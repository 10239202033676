<template>
  <b-modal
      :id="identifier"
      hide-header
      hide-footer
      hide-header-close
      body-bg-variant="gray-lighten"
      size="sm"
  >
    <date-picker
        v-if="filterFromDate === null && filterAfterDate === null"
        v-model="model"
        v-bind="{ ...$attrs }"
        :inline="true"
        :lang="lang"
        @change="$emit('on:calendar-change', model)"
    />
    <template
        v-else
    >
      <date-picker
          v-if="!between"
          v-model="model"
          v-bind="{ ...$attrs }"
          :inline="true"
          :lang="lang"
          :disabled-date="filterFromDate ? (date) => date < filterFromDate : filterAfterDate ?  (date) => date > filterAfterDate : null"
          @change="$emit('on:calendar-change', model)"
      />
      <date-picker
          v-else
          v-model="model"
          v-bind="{ ...$attrs }"
          :inline="true"
          :lang="lang"
          :disabled-date="(date) => date < filterFromDate || date > filterAfterDate "
          @change="$emit('on:calendar-change', model)"
      />
    </template>

    <b-row>
      <b-col align="center">
        <d-button
            :text="'general.actions.close'"
            class="d-btn-ssm mt-3 btn d-btn-danger font-text-title"
            size="sm"
            @on:button-click="hideModal()"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/fr';
import 'vue2-datepicker/locale/nl';
import 'vue2-datepicker/locale/es';

export default {
  components: {
    DatePicker,
  },
  props: {
    model: {
      type: Object,
      default: () => {
      },
    },
    filterFromDate: {
      type: Object,
      default: null,
    },
    identifier: {
      type: String,
      default: 'calendar-modal-id',
    },
    filterAfterDate: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    between: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (changes) {
      this.$bvModal.show(this.identifier);
    },
    hide: function (changes) {
      this.hideModal();
    },
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide(this.identifier);
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/_calendar-modal.scss";
</style>
