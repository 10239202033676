import state from "@/store/modules/authentification/state";
import mutations from "@/store/modules/authentification/mutations";
import actions from "@/store/modules/authentification/actions";
import getters from "@/store/modules/authentification/getters";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
