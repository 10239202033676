import {clubId, _get, _post, _put, _delete} from "@api/doinsport/services/httpService";
import {URL_CLIENT} from "./index";

export const getClientByEnzocard = (ref) =>
  _get(
    '/enzocard/users/' + ref + '/details?club.id=' + clubId
  );

export const getClubClients = (itemsPerPage, page, search, filters = '') =>
  _get(
    URL_CLIENT +
    '?club.id=' + clubId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page +
    '&search=' + search + filters
  )
;

export const exportClients = () =>
  _get(
    URL_CLIENT +
    '.export?order[lastName]=asc' +
    '&club.id=' + clubId
  )
;

export const getRecentClubClients = (page) =>
  _get(
    URL_CLIENT +
    '?club.id=' + clubId +
    '&itemsPerPage=' + 6 +
    '&order[createdAt]=desc' +
    '&page=' + page
  )
;

export const getClubFamilyGroups = (itemsPerPage, page, search) =>
  _get(
    URL_CLIENT +
    '?exists[familyGroup]=false' +
    '&club.id=' + clubId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page + '&search=' + search)
;

export const postClubClient = (customClient) => _post('clubs/clients', customClient);

export const putClubClient = (customClient) => _put('clubs/clients/' + customClient.id, customClient);

export const deleteClubClient = (id) => _delete('clubs/clients/' + id);

export const getClubClient = (id) => _get('clubs/clients/' + id);

export const getClientFromUserClient = (userClientId, idClub) =>
  _get(URL_CLIENT +
    '?club.id=' + clubId +
    '&user.id=' + userClientId
  )
;
