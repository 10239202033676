<template>
  <div class="container-modal" v-on:click="close($event)">
    <div class="block-modal">
      <div class="content">
        <div class="modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
  },
  components: {
  },
  data: () => ({
  }),
  methods: {
    close(e) {
      if (e === undefined || e.target.className === 'container-modal') {
        this.$emit('on:click-close');
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/_right-menu.scss";
</style>
