import {_post} from "@api/doinsport/services/httpService";

export const MEDIA_URL = '/medias'

export const postClubMedia = (formData) => _post(MEDIA_URL,
  formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
;
