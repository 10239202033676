<template>
    <vs-sidebar
        position-right
        size="lg"
        parent="body"
        color="primary"
        class="sidebarx shadow-lg"
        spacer
        id="vsSidebarRef"
        v-model="displayed"
        v-if="renderComponent"
    >

        <b-row>
          <b-col cols="5"><span class="text-danger"><i class="h4 icofont icofont-close pointer" @click.stop="$emit('close')"> </i></span></b-col>
          <b-col><h4 class="text-primary">{{ attrs !== null ? attrs.title : ' ' }}</h4></b-col>
        </b-row>
        <slot name="body"></slot>
    </vs-sidebar>
</template>
<script>
export default {
  props: {
    customizer: {
      type: Object,
      default: { active: false, action: 'hide' },
    },
    parent: {
      type: String,
      default: '',
    },
    attrs: {
      type: Object,
      default: null,
    }
  },
  data: () => ({
    currentRoute: null,
    renderComponent: true,
    displayed: false,
  }),
  mounted() {
    if(this.$router.currentRoute.name !== this.parent) {
        document.getElementById('vsSidebarRef').style.display = 'none';
    }
  },
  watch: {
    customizer: function (val) {
      if (val.action === 'show') {
        this.displayed = true;
      } else {
        this.displayed = false;
      }
    },
  },
}
</script>
<style>
.vs-sidebar {
  background: #fff;
  height: 100vh;
  width: 100%;
  max-width: calc(100% - 200px);
  top: 86px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  z-index: 40000;
}
</style>