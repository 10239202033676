<template>
  <twemoji-textarea
      :emojiData="emojiDataAll"
      :emojiGroups="emojiGroups"
      :enableSendBtn="mode === 'create'"
  >
  </twemoji-textarea>
</template>

<script>
import {
  TwemojiTextarea
} from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/fr/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';

export default {
  props: {
    model: {
      type: String,
      default: ''
    },
    isUpdatable: {
      type: Boolean,
      default: false,
    },
    resetContent: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'create',
    }
  },
  components: {
    'twemoji-textarea': TwemojiTextarea
  },
  watch: {
    isUpdatable: function () {
      if (this.mode === 'update') {
        document.getElementById('twemoji-textarea').innerHTML = this.model;
      }
    },
    editable: function () {
      if (this.mode === 'update') {
        this.emitEmojiContent();
      }
    },
    resetContent: function () {
      if (this.mode === 'update') {
        document.getElementById('twemoji-textarea').innerHTML = '';
      }
    }
  },
  computed: {
    emojiDataAll() {
      return EmojiAllData;
    },
    emojiGroups() {
      return EmojiGroups;
    }
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.onKeyEnter);
  },
  methods: {
    onKeyEnter (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.emitEmojiContent();
      }
    },
    initEmojiListener () {
      document.getElementById('send-btn').addEventListener("click", this.emitEmojiContent);
    },
    emitEmojiContent () {
      this.$emit('on:send-btn-click', document.getElementById('twemoji-textarea').innerHTML);
    }
  },
  mounted() {
    const input = document.getElementById("twemoji-textarea");

    input.addEventListener("keyup", this.onKeyEnter);
    document.getElementById('send-btn').style.padding = 0;
    this.initEmojiListener();
  }
}
</script>
