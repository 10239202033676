export const HOUR_FORMAT = 'HH';
export const MINUTES = 'minutes';
export const DAY = 'day';
export const WEEK = 'week';
export const MONTH = 'month';
export const MINUTE_FORMAT = 'mm';
export const HOUR_MINUTES_FORMAT = 'HH:mm';
export const DATE_DASH_TIME = 'DD/MM/YYYY - HH:mm';
export const YEAR_DASH_MONTH_DASH_DAY = 'YYYY-MM-DD';
export const DAY_SLASH_MONTH_SLASH_YEAR= 'DD/MM/YYYY';
export const UTC_SERVER_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
export const UTC_SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const  getDuration =  (durations) => {
    if (durations < 60) {
        return durations + 'min'
    } else {
        const hours = parseInt(durations / 60);
        const minutes = parseInt(parseInt(durations) - parseInt(hours * 60));

        if (minutes > 0) {
            return hours + 'h' + minutes;
        }
        return hours + 'h';
    }
}
