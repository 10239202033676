export default {
  searchTerm: (context, term) => {
    context.commit('searchTerm',term)
  },
  setNavActive: (context, item) => {
    context.commit('setNavActive',item)
  },
  setActiveRoute:(context, item) => {
    context.commit('setActiveRoute', item)
  }
}
