import Vue from 'vue';

import moment from 'moment-timezone';
import i18n from "@plugins/i18n";

moment.locale(i18n.locale);

const momentPlugin = {
  install(Vue) {
    Vue.prototype.$moment = moment;
  }
}

Vue.use(momentPlugin);

export default momentPlugin;
