<template>
  <div>
    <div class="d-flex align-items-center">
      <slot name="header"></slot>
      <div
        class="pointer width-max-content text-title-font-size mr-2"
        @click="$emit('on:open-or-close')"
      >
        {{ isTranslated ? title : $t(title) }}
      </div>
      <slot name="before-border"></slot>
      <div
        class="pointer w-100 top-border mr-1"
        @click="$emit('on:open-or-close')"
      >
      </div>
      <div>
        <feather
          :type="model ? 'chevron-up' : 'chevron-down'"
          class="background-arrow pointer"
          @click="$emit('on:open-or-close')"
        />
      </div>
    </div>
    <b-collapse
      v-model="model"
      :accordion="accordion"
      role="tabpanel"
    >
      <slot name="body"></slot>
    </b-collapse>
  </div>
</template>
<script>
export default {
  props: {
    accordion: {
      type: String,
      default: 'default'
    },
    type: {
      type: String,
      default: 'default'
    },
    title: {
      type: String,
      default: 'section'
    },
    isTranslated: {
      type: Boolean,
      default: false
    },
    model: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_collapse-section.scss";
</style>
