export default {
  sale: {
    products: [],
    payments: []
  },
  method: null,
  tapeValue: 0,
  rendu: 0,
  customer: null,
  showModal: false
}
