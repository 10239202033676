<template>
  <div>
    <div class="block-notification-access" v-if="show" :class="getClassEvent(event)">
      <template v-if="client">
        <div class="fullname">{{ client.fullName }}</div>
        <div class="img-client" v-if="client.profilePictureContentUrl">
          <img :src="$filePath(client.profilePictureContentUrl)"/>
        </div>
        <div class="icon" v-if="!client.profilePictureContentUrl">
          <b-icon
            :icon="(event.Incidence !== 'Granted') ? 'dash' : 'check2'"
          >
          </b-icon>
        </div>
      </template>
      <div class="status">{{ $t('access.status.' + event.Incidence) }}</div>
      <div class="icon" v-if="!client">
        <b-icon
          :icon="(event.Incidence !== 'Granted') ? 'dash' : 'check2'"
        >
        </b-icon>
      </div>
      <div v-if="client" class="btn-client" @click="showDetailClient()">{{ $t('views.client.customer-details') }}</div>
    </div>
  </div>
</template>
<script>

import {getClientByEnzocard} from "@api/doinsport/services/client/client.api";

export default {
  data: () => ({
    count: 0,
    client: null,
    statusAccess: [],
    event: null,
    show: false
  }),
  computed: {
    getCurrentEvent() {
      return this.event;
    }
  },
  methods: {
    showDetailClient() {
      if (this.client) {
        this.$router.push({name: 'customer_details', params: {id: this.client.id}});
      }
    },
    getClassEvent(event) {
      let cssClass = '';
      switch (event.Incidence) {
        case 'Granted':
          cssClass = 'success-notif';
          break;
        case '':
          cssClass = 'warning-notif';
          break;
        default:
          cssClass = 'danger-notif';
          break;
      }
      return cssClass;
    }
  },
  created() {

    const accessService = this.$store.getters['auth/currentClub'].accessControlService;

    if (accessService) {
      if (accessService.readerAuthenticationToken) {
        const domaineWS = accessService.apiUrl.replace('https://', '').replace('/api', '').replace('.api', '-1.readers');
        let url = "wss://" + domaineWS + "?reader1&token=" + accessService.readerAuthenticationToken;
        if (url.includes('demo-1')) {
          url = url.replace('demo-1', 'demo');
        }

        this.connection = new WebSocket(url);
        this.connection.onmessage = (event) => {
          if (event) {
            if (event.data) {
              const data = JSON.parse(event.data);
              if (data.event) {
                const eventData = data.event;

                if (eventData) {
                  this.event = null;
                  this.client = null;
                  this.event = eventData.event;

                  const userId = this.event.userId;
                  if (userId) {
                    getClientByEnzocard(userId).then(response => {
                      if (response) {
                        if (response.data) {
                          this.client = response.data;
                        }
                      }
                    }).catch(err => {
                      console.log('Oups, an error : ', err);
                    });
                  }

                  this.show = true;
                  this.count++;
                  setTimeout(() => {
                    this.count = this.count - 1;
                    if (this.count < 1) {
                      this.event = null;
                      this.show = false;
                    }
                  }, 10000);
                }
              }
            }
          }
        }
      }
    }

  },
  mounted() {

  },
  beforeDestroy() {
    try {


    } catch (e) {
    }
  }
}
</script>

<style lang="scss" scoped>

.block-notification-access {
  z-index: 99;
  display: flex;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #e9faf1;
  border-radius: 20px;
  box-shadow: 0 0 10px 5px rgba(145, 145, 145, 0.2);
  min-height: 200px;
  height: auto;
  max-width: 300px;
  width: 100%;
  padding: 20px;
  flex-direction: column;

  .status {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    width: 100%;
  }

  .btn-client {
    background-color: #e9faf1;
    color: #0c0b0b;
    border-radius: 20px;
    padding: 5px 10px 5px 10px;
    text-align: center;
    width: 90%;
    font-size: 15px;
    font-weight: 600;
    margin: auto;
    margin-top: 10px;
    cursor: pointer;
  }

  .icon {
    text-align: center;
    font-size: 60px;
    color: white;
    margin: auto;
  }

  .fullname {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    width: 100%;
  }

  .img-client {
    width: 100%;
    text-align: center;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      text-align: center;
    }
  }
}

.success-notif {
  background-color: #00cf14 !important;

  .status {
    color: #343434 !important;
  }
}

.warning-notif {
  background-color: #E6B566 !important;

  .status {
    color: #343434 !important;
  }
}

.danger-notif {
  background-color: #E5707E !important;

  .status {
    color: #343434 !important;
  }
}

</style>
