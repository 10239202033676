import en from "@/locales/en.json"
import fr from "@/locales/fr.json"
import nl from "@/locales/nl.json"
import de from "@/locales/de.json"

export default {
  en,
  fr,
  nl,
  de
};
