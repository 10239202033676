<template>
  <div
    class="container-fluid"
    :class="fullBody ? 'fullBodyTopbar' + topMargin: 'customBodyTopBar'+ topMargin"
  >
    <b-card
      class="pb-0"
      :class="innerWidth ? 'card-height' : 'pt-2'"
    >
      <b-card-text>
        <div v-if="!isIconsContent" class="row">
          <div class="col-md-7">
            <h6 class="title-top"> {{ title }} </h6>
            <div class="tree-structure-top" :class="mobileFont">
              <slot name="tree"></slot>
            </div>
          </div>
          <div class="col-md-5 align-content-end">
            <slot name="icons"></slot>
          </div>
        </div>
        <div v-else>
          <div
            :class="innerWidth ? 'pull-left' : ''"
          >
            <h6 class="title-top"> {{ title }}</h6>
            <div class="tree-structure-top" :class="mobileFont">
              <slot name="tree"></slot>
            </div>
          </div>
          <div
            :class="innerWidth ? 'pull-right' : ''"
          >
            <slot name="icons"></slot>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    isIconsContent: {
      type: Boolean,
      default: true
    },
    fullBody: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    mobileFont() {
      return this.$store.getters['layout/getInnerWidth'] < 400 ? 'smaller-font' : '';
    },
    topMargin() {
      return this.innerWidth ? ' mt-4' : ' mt-2';
    },
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 700;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/_body-top-bar.scss";
</style>
