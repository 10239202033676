export default {
  searchTerm: (state, term) => {
    let items = [];
    term = term.toLowerCase();
    state.data.filter(menuItems => {
      if(menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link'){
        items.push(menuItems);
      }
      if(!menuItems.children) return false
      menuItems.children.filter(subItems => {
        if(subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if(!subItems.children) return false
        subItems.children.filter(suSubItems => {
          if(suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })
      })
      state.searchData = items
    });
  },
  resetTabSelector: (state, selectedTab) => {
    for (const i in state.tabs[selectedTab]) {
        state.tabs[selectedTab][i].active = i < 1;
    }
  },
  setNavActive: (state, item) => {
    if (!item.active) {
      state.data.forEach(a => {
        if (state.data.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  },
  setActiveRoute:(state, item) => {
    state.data.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }
}
