import Body from '../components/core/body';
import planning from "@/router/planning";
import events from "@/router/events";
import dashboard from "@/router/dashboard";
import bookings from "@/router/bookings";
import marketing from "@/router/marketing";
import playground from "@/router/playground";
import clients from "@/router/clients";
import auth from "@/router/auth";
import payment from "@/router/payment";
import settings from "@/router/settings";
import users from "@/router/users";
import shop from "@/router/shop";
import subscriptions from "@/router/subscriptions";
import credits from "@/router/credits";
import news from "@/router/news";
import creditsPacks from "@/router/credits-packs";
import accessControl from "@/router/access-control";
import walletRefills from "@/router/wallet-refills";
import sales from "@/router/sales";
import liveAccesses from "@/router/live-accesses";
import caisse from "@/router/caisse";

const routes = [
  {
    path: '', redirect: {name: 'form-client'}
  },
  {
    path: '/',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      dashboard
    ]
  },
  auth
];

export default routes;
