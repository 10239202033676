export default {
  getBlockPrices: (state) => {
    return state.blockPrices;
  },
  getPriceCategories: (state) => {
    return state.priceCategories;
  },
  getTmpBooking: (state) => {
    return state.tmpBooking;
  },
}
