<template>
  <b-sidebar
    v-model="model"
    v-bind="{ ...$attrs }"
    :id="identifier"
    :ref="identifier"
    :aria-controls="identifier"
    :style="styles"
    :backdrop="backdrop"
    :right="rightPosition"
    shadow
    no-header
    @hidden="$emit('hidden')"
    @shown="$emit('shown')"
    @change="$emit('change')"
  >
    <template #default="{ hide }">
      <b-row :class="headerClass">
        <b-col
          v-if="rightPosition"
          align="left"
          cols="1"
          class="pl-4 mt-3"
        >
          <div class="flex-start">
            <span
              class="icofont icofont-close pointer"
              :class="closeIcon"
              @click="hide()"
            ></span>
          </div>
        </b-col>
        <b-col
          class="d-flex align-items-center"
        >
          <slot name="header"/>
        </b-col>
        <b-col
          v-if="!rightPosition"
          align="middle"
          cols="1"
          class="pl-0"
        >
          <div class="flex-end">
            <span
              class="icofont icofont-close pointer"
              :class="closeIcon"
              @click="hide()"
            ></span>
          </div>
        </b-col>
      </b-row>
      <slot name="body"/>
      <slot name="footer"/>
    </template>
  </b-sidebar>
</template>
<script>
export default {
  props: {
    identifier: {
      type: String,
      default: 'SIDEBAR_ID'
    },
    model: {
      type: Boolean,
      default: false
    },
    backdrop: {
      type: Boolean,
      default: true
    },
    rightPosition: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'auto'
    },
    left: {
      type: String,
      default: 'auto'
    },
    right: {
      type: String,
      default: 'auto'
    },
    top: {
      type: String,
      default: '61px'
    },
    bgDark: {
      type: String,
      default: 'unset'
    },
    index: {
      type: String,
      default: '100000'
    },
    headerClass:{
      type: String,
      default:'custom-sidebar-header'
    },
    closeIcon:{
      type:String,
      default:'close-icon'
    }
  },
  computed: {
    styles() {
      return {
        '--width': this.width,
        '--left': this.left,
        '--right': this.right,
        '--top': this.top,
        '--bg-dark': this.bgDark,
        '--z-index': this.index
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/_b-side-bar.scss";
@import "@lazy/_custom-sidebar.scss";
</style>
