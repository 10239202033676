<template>
  <d-dropdown-form
    :hide="hide"
    :show="show"
    :right="true"
    :identifier="identifier"
  >
    <template v-slot:dropdownbutton>
      <span
        class="icon-more-alt d-icon-gray"
        @on:button-click="show = !show"
      />
    </template>
    <template v-slot:dropdownbody>
      <template
        v-for="(menu, i) in menus"
      >
        <a
          href="#"
          class="dropdown-item"
          @click="$emit(`on:${ menu.name }`);hide = !hide"
        >
          <div
            class="simple-label-avenir pointer"
          >
            <i
              :class="menu.icon"
              class="pr-1"
            />
            {{ $t(`general.actions.${menu.name}`) }}
          </div>
        </a>
        <div
          v-if="parseInt(i) < menus.length -1"
          class="border-bottom"
        />
      </template>
    </template>
  </d-dropdown-form>
</template>
<script>
const ACTIONS_IDENTIFIER = 'ACTIONS_IDENTIFIER';

export default {
  data: () => ({
    hide: false,
    show: false,
  }),
  props: {
    identifier: {
      type: String,
      default: ACTIONS_IDENTIFIER
    },
    menus: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style lang="scss" scoped>
/deep/ .dropdown-menu {
  min-width: auto;

  &.show {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  > li {
    line-height: 35px;

    &:not(:last-child) {
      border-bottom: 1px solid #DDD;
    }
  }
}

/deep/ .dropdown-item {
  padding: 0.5rem 1em;

  &:focus {
    color: #16181b;
    text-decoration: none;
    background-color: unset;
  }

  &:active {
    color: #fff;
    text-decoration: none;
    background-color: #919194;
  }

  &:hover {
    background-color: #EDEFF2;
  }
}

.d-icon-gray {
  font-size: 1.5rem;
}
</style>>
