import { URL_CLIENT } from "@api/doinsport/services/client";
import {_delete, _get, _post} from "@api/doinsport/services/httpService";

export const DOCUMENTS_URL = URL_CLIENT + '/documents'

export const postClubClientDocument = (formData) => _post(
  DOCUMENTS_URL,
    formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
;

export const deleteClubClientDocument = (iri) => _delete(iri);

export const getClubClientDocument = (iri) => _get(iri);
