import { URL } from "./index";
import {_delete, _get, _post} from "@api/doinsport/services/httpService";

export const PLAYGROUND_URL_PHOTOS = `${URL}/photos`;

export const getClubPlaygroundPhoto = (id) => _get(id);

export const postClubPlaygroundPhoto = (formData) => _post(PLAYGROUND_URL_PHOTOS,
    formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
;

export const deleteClubPlaygroundPhoto = (id) => _delete(PLAYGROUND_URL_PHOTOS + '/' + id);
