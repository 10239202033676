import {_delete, _post, _put} from "@api/doinsport/services/httpService";

export const CLUB_URL_PHOTOS = 'clubs/photos'

export const putClubPhoto = (id, data) => _put(CLUB_URL_PHOTOS + '/' + id, data);

export const postClubPhoto = (formData) => _post(CLUB_URL_PHOTOS,
    formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
;

export const deleteClubPhoto = (id) => _delete(CLUB_URL_PHOTOS + '/' + id);
