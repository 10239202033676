export default {
  set: (state, payload) => {
    state.operation = payload;
  },
  inWork: (state, payload) => {
    state.isBusy = true;
  },
  release: (state, payload) => {
    state.isBusy = false;
  },
}
