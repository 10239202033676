<template>
  <date-picker
    v-model="model"
    v-bind="{ ...$attrs }"
    :inline="true"
    :lang="lang"
    @change="$emit('on:calendar-change', model)"
  />
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: {
    model: {
      type: Object,
      default: () => {
      },
    },
    disabledDatesOperator: {
      type: String,
      default: null,
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('calendar-modal-id');
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .mx-calendar-content .cell.active {
  color: #fff;
  background-color: #0B2772;
  border-radius: 3px;
  padding-top: 0px;
}

.mx-datepicker-range {
  border: 1px solid #e4e3e3;
}
</style>
