import {clubId, _delete, _get, _post, _put} from "@api/doinsport/services/httpService";

const URL = '/products';

export const PRODUCT_URL_PHOTOS = `${URL}/photos`;

export const getAllProducts = (itemsPerPage, page, filter, props = '') =>
  _get(
    URL +
    '?club.id=' +clubId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page +
    '&search=' + filter + props
  )
;

export const getProduct = (id) => _get(URL + '/' + id);

export const deleteProduct = (id) => _delete(URL + '/' + id);

export const postProduct = (data) => _post(URL, data);

export const putProduct = (data) => _put(URL + '/' + data.id, data);

export const getAllCategories = (page = 1, itemsPerPage = 30) =>
  _get(
    URL +
    '/categories' +
    '?club.id=' + clubId +
    '&page=' + page +
    '&itemsPerPage=' + itemsPerPage
  )
;

export const postProductCategory = (data) => _post(URL + '/categories', { club: '/clubs/' + clubId, ...data });

export const putProductCategory = (data) => _put(URL + '/categories/' + data.id, data);

export const deleteProductCategory = (id) => _delete(URL + '/categories/' + id);

export const postProductPhoto = (formData) => _post(PRODUCT_URL_PHOTOS,
  formData, {
    headers: {
        "Content-Type": "multipart/form-data"
    }
  })
;

export const deleteProductPhoto = (id) => _delete(URL + '/photos/' + id);
