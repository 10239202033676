import Vue from 'vue'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';


Vue.use(VueTelInput, {
  autoFormat: true,
  mode: 'international',
  dropdownOptions: {
    showDialCodeInList: true,
    showDialCodeInSelection: false,
    showFlags: true,
  },
  inputOptions: {
    required: true,
  }
});

export default VueTelInput;
