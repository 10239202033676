import {MEDIA_URL} from "@api/doinsport/services/medias/media.api";
import {NEWS_URL_PHOTOS} from "@api/doinsport/services/news/news.api";
import {CLUB_URL_PHOTOS} from "@api/doinsport/services/club/club-photo.api";
import {PRODUCT_URL_PHOTOS} from "@api/doinsport/services/products/products.api";
import {CLUB_URL_LOGO} from "@api/doinsport/services/club/club-logo";
import {FILES_URL} from "@api/doinsport/services/club/club-client-imports.api";
import {DOCUMENTS_URL} from "@api/doinsport/services/client/document/document.api";
import {CUSTOMER_IDENTITY_PHOTOS_URL} from "@api/doinsport/services/client/identity-photo/identity-photo.api";
import {AVATARS_URL} from "@api/doinsport/services/user-club/avatar/avatar.api";
import {BLOCK_PRICE_URL_PHOTOS} from "@api/doinsport/services/timetable/blocks/price/photo/photo.api";
import {PLAYGROUND_URL_PHOTOS} from "@api/doinsport/services/playground/playground-photo.api";
import {_post} from "@api/doinsport/services/httpService";
import {OPTION_PHOTO} from "@api/doinsport/services/option/option-photo.api";
import {URI_SUBSCRIPTION_PLAN_DOCUMENT} from "@api/doinsport/services/subscription-plans/subscription-plan-document/subscription-plan-document.api";
import {BLOCK_PRICE_CATEGORY_PHOTO_URL} from "@api/doinsport/services/timetable/blocks/price/category/photo/category-photo.api";

const headers = { "Content-Type": "multipart/form-data" };

export const getUrlFromTarget = (payload, formData) => {
  const target = payload.target;

  switch (target) {
    case 'clubPhoto':
      return CLUB_URL_PHOTOS;
    case 'subscriptionPlanDocument':
      formData.append('name', payload.name);
      return URI_SUBSCRIPTION_PLAN_DOCUMENT;
    case 'optionPhoto':
      return OPTION_PHOTO;
    case 'blockPriceCategoryPhoto':
      return BLOCK_PRICE_CATEGORY_PHOTO_URL;
    case 'clubMedia':
      return MEDIA_URL;
    case 'postClubNewsPhoto':
      formData.append('position', 0);
      return NEWS_URL_PHOTOS
    case 'postProductPhoto':
      return PRODUCT_URL_PHOTOS;
    case 'postClubLogo':
      return CLUB_URL_LOGO;
    case 'postClubClientImportFile':
      return FILES_URL;
    case 'postClientDocument':
      formData.append('name', payload.name);
      return DOCUMENTS_URL;
    case 'postClientIdentityPhoto':
      return CUSTOMER_IDENTITY_PHOTOS_URL;
    case 'postUserClubAvatar':
      return AVATARS_URL;
    case 'postBlockPricePhoto':
      return BLOCK_PRICE_URL_PHOTOS;
    default:
      return PLAYGROUND_URL_PHOTOS;
  }
}

export const postPhoto = (URL, formData) => _post(URL,
  formData, {
    headers: headers
  })
;
