export default {
  authStatus: (state) => state.status,
  currentUser: (state) => {
    if (!state.user) {
      state.user = JSON.parse(localStorage.getItem('user'));
    }
    return state.user
  },
  currentClub: (state, dispatch) => {
    if (state.club === null || state.club === undefined) {
      dispatch('getSelectedClub').then(resp => {
        return resp.data;
      });
    }
    return state.club
  },
}
