import {_delete, _get, _put} from "@api/doinsport/services/httpService";

const URI = "clubs/subscription-plans";

const URI_DOCUMENT = "/documents";

export const URI_SUBSCRIPTION_PLAN_DOCUMENT = URI + URI_DOCUMENT;

export const getSubscriptionPlanDocuments = (id) => _get(URI +  '/' + id + URI_DOCUMENT);

export const deleteSubscriptionPlanDocument = (id) => _delete(URI_SUBSCRIPTION_PLAN_DOCUMENT + '/' + id);

export const putSubscriptionPlanDocument = (id, data) => _put(URI_SUBSCRIPTION_PLAN_DOCUMENT + '/' + id, data);
