import menu from './modules/menu/index';
import auth from './modules/authentification/index';
import language from './modules/language/index';
import layout from './modules/layout/layout';
import currency from './modules/currency/index';
import blocks from './modules/blocks/index';
import client from './modules/client/index';
import playgrounds from './modules/playgrounds/index';
import subscriptionPlan from './modules/subscription-plan/index';
import blockPrice from './modules/block-price/index';
import planning from './modules/planning/index';
import forms from './modules/form/index';
import copyMove from './modules/copy-move/index';
import events from './modules/events/index';
import nextore from './modules/nextore/index';
import booking from './modules/booking/index';
import validator from './modules/validator/index';
import dashboard from './modules/dashboard/index';
import multipleBooking from './modules/multiple-booking/index';
import 'es6-promise/auto';

let initialState = {
  validator: validator.state,
  nextore: nextore.state,
  client: client.state,
  auth: auth.state,
  dashboard: dashboard.state,
  events: events.state,
  booking: booking.state,
  currency: currency.state,
  forms: forms.state,
  blocks: blocks.state,
  subscriptionPlan: subscriptionPlan.state,
  blockPrice: blockPrice.state,
  menu: menu.state,
  language: language.state,
  layout: layout.state,
  playgrounds: playgrounds.state,
  planning: planning.state,
  copyMove: copyMove.state,
  multipleBooking: multipleBooking.state,
};

const DEFAULT_STATE = JSON.stringify(initialState);

export const dependencies = {
  modules: {
    client,
    auth,
    nextore,
    booking,
    forms,
    menu,
    dashboard,
    blocks,
    layout,
    events,
    currency,
    language,
    copyMove,
    planning,
    validator,
    blockPrice,
    playgrounds,
    multipleBooking,
    subscriptionPlan,
  },
  mutations: {
    reset(state) {
      let store = JSON.parse(DEFAULT_STATE);

      Object.keys(state).forEach(key => {
        Object.assign(state[key], store[key])
      });
    }
  },
  strict: false,
};

