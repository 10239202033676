<template>
  <vs-dropdown
    v-if="renderComponent"
    :key="'dropdown-key' + key"
    vs-custom-content
    vs-trigger-click
    class="ml-1 outlined-none"
    ref="dropdown"
  >
    <slot name="dropdownbtn"></slot>
    <vs-dropdown-menu
      :style="getStyle"
    >
      <slot name="dropdownbody"></slot>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>
<script>
export default {
  data: () => ({
    active: '',
    key: 0,
    renderComponent: true,
  }),
  props: {
    top: {
      type: String,
      default: null
    },
    left: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: '250px'
    },
    hide: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    getStyle() {
      const top = this.top ? 'top: ' + this.top + ' !important;' : '';

      const left = this.left ? 'left: ' + this.left + ' !important;' : '';

      return left + top + 'width: ' + this.width;
    }
  },
  watch: {
    hide: function (val) {
      this.key++;
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.outlined-none:focus {
  outline: 0;
}

/deep/ .vs-dropdown--menu--after {
  display: none;
}

/deep/ .vs-dropdown--custom {
  padding: 0 !important;
}

</style>
