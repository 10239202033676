import axios from 'axios';
import {JSON_CONTENT_TYPE} from "@api/doinsport/config";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": JSON_CONTENT_TYPE,
    Authorization: "Bearer " + JSON.parse(localStorage.getItem('token'))
  }
});

export default httpClient;
