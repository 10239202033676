export const PROD_END_POINT = 'https://v3.doinsport.club/';

export const defaultLocale = "fr";

export const localeOptions=[
    {
        id:'en',name:'English'
    },
    {
        id:'fr',name:'French'
    },
    {
        id:'nl',name:'Dutch'
    },
    {
        id:'de',name:'German'
    },
];

export const STATIC_PLANNING_DATE_TIMEZONE = '2021-05-28T12:00:00.000Z';
