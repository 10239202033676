import state from "@/store/modules/booking/state";
import getters from "@/store/modules/booking/getters";
import actions from "@/store/modules/booking/actions";
import mutations from "@/store/modules/booking/mutations";

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
