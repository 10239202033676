import Vue from "vue";
import {currency} from "@/utils/currency";

const currencyPlugin = {
  install (Vue, options) {
    Vue.prototype.$currency = currency();
  },
}

Vue.use(currencyPlugin);

export default currencyPlugin;
