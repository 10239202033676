import Vue from 'vue';
import Router from "vue-router";
import routes from "@/router";
import store from "@/plugins/store";

Vue.use(Router);

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/auth/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (to && to.path.includes('playground') && false === to.path.includes('plannings') ) {
    const storedPlanningConfig = store.getters["planning/getBlocksByDate"];

    if (storedPlanningConfig.length > 0) {
      store.commit('planning/resetBlocksByDate');
      store.commit('playgrounds/setSelectedBlockPrices', []);
    }
  }
  if (authRequired && !loggedIn) {
    return next('/auth/login');
  }

  router.prevRoute = from;

  next();
})

export default router;
