import {clubId, _post} from "@api/doinsport/services/httpService";

const URI = 'clubs/client-imports';

export const FILES_URL = `${URI}/files`;

export const postClubClientImport = (data) => _post(URI, {
    club: '/clubs/' + clubId,
    ...data
});

export const postClubClientImportFile = (formData) => _post(URI + '/files',
    formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
;
