export default {
  setStartDate: (state, payload) => {
    state.startDate = payload;
  },
  setCurrentPage: (state, payload) => {
    state.currentPage = payload;
  },
  setEndDate: (state, payload) => {
    state.endDate = payload;
  }
}
