<template>
  <validation-provider
      v-slot="{ errors, ariaInput }"
      :name="$t(label)"
      :rules="rules"
      :class="!rules.valid ? 'error-focus' : ''"
  >
    <vue-tel-input
        v-if="model === null || model === ''"
        v-model="model"
        v-bind="{ ...$attrs, ...ariaInput }"
        :size="size"
        :default-country="countryCode"
        :class="className"
        :input-options="{ placeholder: placeHolder }"
        :autocomplete="autocomplete"
    />
    <vue-tel-input
        v-else
        v-model="model"
        v-bind="{ ...$attrs, ...ariaInput }"
        :size="size"
        :default-country="countryCode"
        :class="className"
        :input-options="{ placeholder: placeHolder }"
        :state="errors.length === 0"
        :autocomplete="autocomplete"
    />
    <b-form-invalid-feedback
        v-if="!rules.valid"
        :state="rules.valid"
    >
      {{ rules.message }}
    </b-form-invalid-feedback>
    <b-form-invalid-feedback
        v-if="model !== ''"
        :state="errors.length === 0"
    >
      {{ $t(errorText) }}
    </b-form-invalid-feedback>
    <div v-else>
      <b-form-invalid-feedback
          :state="errors.length === 0"
      >
        {{ $t(errorText) }}
      </b-form-invalid-feedback>
    </div>
  </validation-provider>
</template>

<script>
export default {
  props: {
    textFieldName: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    className: {
      type: String,
      default: ''
    },
    placeHolder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    size: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: this,
    },
    max: {
      type: Number,
      default: this,
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    model: function() {
      if (!this.rules.valid) {
        this.rules.valid = true;
      }
    }
  },
  computed: {
    countryCode() {
      return this.$store.getters["auth/currentClub"].country;
    },
    model: {
      get() {
        return this.value;
      },
      set(model) {
        this.$emit('input', model);
      },
    },
  },
  methods: {
    getLabel(ariaInput) {
      let label = this.$t(this.label);

      if (undefined !== ariaInput['aria-required'] && ariaInput['aria-required'] === 'true') {
        label += '*';
      }

      return label;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@lazy/_phone-number.scss";

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + -3.25rem);
}

/deep/ .form-control[readonly] {
  background-color: #EAF2F7;
  cursor: pointer;
}

.error-focus .vue-tel-input {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.2);
}

</style>
