export default {
  authRequest(state) {
    state.status = 'loading';
  },
  authSuccess(state, token) {
    state.status = 'success';
    state.token = token;
  },
  authMe(state, user) {
    state.user = user;
    state.status = 'success';
  },
  selectedClub(state, club) {
    state.club = club;
  },
  authError(state) {
    state.status = 'error';
  },
  logout(state) {
    state.status = '';
    state.token = '';
  }
}
