import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  addProduct: (state, payload) => {
    if (state.tapeValue !== 0 && state.tapeValue !== null) {
      payload.nbArticle = state.tapeValue;
    } else {
      payload.nbArticle = 1;
    }

    state.sale.products.push(payload);
    state.showModal = true;
  },
  addPayment: (state, payload) => {

    let amount = 0;
    state.sale.products.forEach(product => {
      amount = amount + (product.price * product.quantity);
    });
    state.sale.payments.forEach(payment => {
      amount = amount - payment.value;
    });

    if (parseFloat(state.tapeValue) > 0 && state.tapeValue >= amount) {
      payload.value = parseFloat(amount).toFixed(2);
      state.rendu = state.tapeValue - amount;
    } else {
      if (parseFloat(state.tapeValue) > 0) {
        payload.value = parseFloat(state.tapeValue).toFixed(2);
      } else {
        payload.value = parseFloat(amount).toFixed(2);
      }
      state.rendu = 0;
    }

    state.sale.payments.push(payload);
    state.tapeValue = 0;
  },
  setMethod: (state, payload) => {
    state.method = payload;
  },
  setCustomer: (state, payload) => {
    state.customer = payload;
  },
  setShowModal: (state, payload) => {
    state.showModal = payload;
  },
  removeProduct: (state, payload) => {
    state.sale.products.splice(payload, 1);
  },
  clearSale: (state, payload) => {
    state.sale.products = [];
    state.sale.payments = [];
    state.customer = null;
    state.showModal = false;
  },
  updatePrice: (state, payload) => {
    if (state.sale.products[payload]) {
      state.sale.products[payload].price = parseFloat(state.tapeValue).toFixed(2);
    }
    state.tapeValue = 0;
  },
  updateQuantity: (state, payload) => {
    if (state.sale.products[payload]) {
      state.sale.products[payload].quantity = parseFloat(state.tapeValue).toFixed(2);
    }
    state.tapeValue = 0;
  },
  setTapeValue: (state, payload) => {
    state.tapeValue = payload;
  },
  setRendu: (state, payload) => {
    state.rendu = payload;
  }
}
