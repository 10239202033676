<template>
  <!-- Page Header Start-->
  <div :key="'header' + componentKey" class="page-main-header" :class="{ open:toggle_sidebar_var }">
    <div
      class="main-header-right row"
    >
      <template>
        <div v-if="$store.getters['layout/getInnerWidth'] > 761" class="col-md-4">
          <li class="club-logo-margin">
            <template>
              <img
                v-if="logo || clubLogo"
                id="clubLogoId"
                class="screen-reset img-cover"
                width="100"
                height="28"
                :src="clubLogo"
              >
            </template>
            <clubs-selection
              :class="$store.getters['layout/getInnerWidth'] <= 995 ?  'ml-4' : ''"
              :clubTitle="clubTitle"
            />
          </li>
        </div>
        <div
          v-show="$route.name === 'planning'"
          :class="$store.getters['layout/getInnerWidth'] > 761 ? 'd-flex justify-content-center col-md-4' : ''"
        >
          <div
            v-if="$store.getters['layout/getInnerWidth'] > 761"
            class="label-club-font planning-day-label f-w-700"
          >
            {{ planningDate }}
          </div>
        </div>
        <div class=" d-flex align-items-center align-content-center  mb-0 pr-0 "
             :class="getStyles"
        >
          <div
            v-if="$store.getters['layout/getInnerWidth'] <= 760"
            class="mt-1"
          >
            <i
              id="burger-icon-id"
              class="icofont icofont-navigation-menu hamburger-logo"
            >
            </i>
          </div>
          <ul class="nav-menus display-content" :class="{ open: mobiletoggle2}">
            <li v-if="$store.getters['layout/getInnerWidth'] <= 761">
              <clubs-selection
                :clubTitle="clubTitle"
              />
            </li>
            <li v-if="$store.getters['layout/getInnerWidth'] <= 761">
              <div class="label-club-font planning-day-label f-w-700 mr-2">
                {{ planningDate }}
              </div>
            </li>
            <li @click="goToPlaylist()" class="pointer">
              <i class="icofont icofont-light-bulb guide-icon"></i>
              <label class="mb-0 pointer guide-color">
                {{ $t('components.core.header.guide') }}
              </label>
            </li>
            <li class="p-0 m-0">
              <b-nav-item-dropdown
                class="p-0 m-0 lang-btn"
              >
                <template slot="button-content">
                  <img
                    width="18px"
                    height="18px"
                    :src="'https://www.doinsport.club/assets/flags/'+$i18n.locale+'.png'"
                  />
                </template>
                <b-dropdown-item
                  v-for="(lang, i) in langs"
                  v-bind:key="i"
                  @click="switchLocale(lang.img)"
                >
                  <img
                    width="20px"
                    :src="'https://www.doinsport.club/assets/flags/' + lang.img + '.png'"
                  />
                  {{ lang.lang }}
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </li>
            <li
              v-if="$store.getters['auth/currentClub'].cashRegisterType === 'nextore'"
              class="border-left"
            >
              <img
                :src="require('@/assets/caisse.svg')"
                class="pointer ml-2 mr-2"
                width="20px"
                @click="goToCaisse()"
              />
            </li>
            <li class="border-left border-right">
              <settings-selection/>
            </li>
            <li v-if="false">
              <span class="icons-nav-size bg-doinsport-primary fa fa-life-saver"></span>
            </li>
            <li
              v-if="false"
              class="mr-3 border-right"
            >
              <span class="icons-nav-size bg-doinsport-primary fa fa-bell-o"></span>
            </li>
            <li
              :class="$store.getters['layout/getInnerWidth'] <= 800 ? 'mr-0' : 'mr-2'"
            >
              <span
                @click="logout()"
                class="ml-2 mt-1 mr-2 icons-size-disconnect fa fa-power-off pointer text-danger"
              >
              </span>
            </li>
            <li>
              <div class="d-flex align-items-center user-name">
              <span class="media-body">
                <span
                  style="font-size: 12px"
                  class="f-w-400 mr-2 mt-2"
                >
                  {{ firstName }} {{ lastName }}
                </span>
                <span class="d-block">
                  {{ userName }}
                </span>
              </span>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <notification></notification>
  </div>
  <!-- Page Header Ends -->
</template>
<script>
import Bus from "@/utils/bus";

var body = document.getElementsByTagName("body")[0];
import {mapState} from "vuex";
import {capitalize} from "@/utils/string";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import moment from "moment-timezone";
import SettingsSelection from "@custom/core/SettingsSelection";
import Notification from "@custom/access/Notification";

export default {
  name: 'Search',
  data() {
    return {
      langs: [{img: 'fr', lang: 'Français'}, {img: 'en', lang: 'Anglais'}, {img: 'it', lang: 'Italien'}],
      firstName: '',
      lastName: '',
      userName: '',
      terms: '',
      searchOpen: false,
      searchResult: false,
      searchResultEmpty: false,
      toggle_sidebar_var: false,
      clicked: false,
      toggle_rightsidebar_var: false,
      rightclicked: false,
      mobile_toggle: false,
      mobile_search: false,
      mobiletoggle1: false,
      mobiletoggle2: false,
      title: '',
      componentKey: 1,
      logo: ''
    }
  },
  computed: {
    getStyles() {
      if (this.$store.getters['layout/getInnerWidth'] >= 761) {
        return 'col-md-4 justify-content-end'
      } else if (this.$store.getters['layout/getInnerWidth'] < 761) {
        return 'col-md-12 justify-content-between pl-0'
      } else {
        return 'justify-content-end '
      }
    },
    planningDate() {
      if (this.$route.name === 'planning') {
        const date = this.$store.getters["planning/getDate"];
        if (isNotUndefinedAndNotNull(date)) {
          if (this.$store.getters['layout/getInnerWidth'] <= 1070) {
            return this.$moment.utc(date).format('DD/MM/YY');
          } else {
            let day = this.$moment.utc(date).format('dddd');
            return day.charAt(0).toUpperCase() + day.slice(1) + ' ' + this.$moment.utc(date).locale(this.$i18n.locale).format('LL').replace(' 00:00', '');
          }
        }
      }
    },
    clubTitle() {
      if (localStorage.getItem('current-club') === null) {
        if (this.$store.getters['layout/getInnerWidth'] <= 500) {
          return (this.title).substring(0, 6);
        } else {
          return this.title;
        }
      } else {
        if (this.$store.getters['layout/getInnerWidth'] <= 500) {
          return (this.$store.getters["auth/currentClub"].name).substring(0, 6);
        } else {
          return this.$store.getters["auth/currentClub"].name;
        }
      }
    },
    clubLogo() {
      return this.loadLogoUrl();
    },
    ...mapState({
      menuItems: state => state.menu.searchData,
      layout: state => state.layout.layout
    })
  },
  mounted() {
    // TODO : GET User from store
    const user = JSON.parse(localStorage.getItem('user'));
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.userName = user.username;
    Bus.$on('on:current-club-loaded', (club) => {
      this.setTitle(club.name);
      this.setLogo(club.logo);
    })
  },
  methods: {
    goToCaisse() {
      window.open('https://caisse.doinsport.club/pos', '_blank');
    },
    goToPlaylist() {
      window.open('https://support.doinsport.com/', '_blank');
    },
    loadLogoUrl() {
      const url = this.$store.getters["auth/currentClub"].logo;

      return isNotUndefinedAndNotNull(url) ? this.getLogoPath(url.contentUrl) : null;
    },
    getLogoPath(url) {
      if (!url) {
        return '';
      }
      const uri = process.env.VUE_APP_UPLOAD_FILE_PATH;

      if (uri) {
        return uri.replace('{token}', url);
      }
    },
    setTitle(title) {
      this.title = title;
    },
    setLogo(logo) {
      this.logo = logo;
    },
    getLogo() {
      return this.logo;
    },
    getTitle() {
      return this.title;
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$moment.locale(locale);
        localStorage.setItem('language', locale);
        this.$bus.$emit('language-changed', locale);
      }
    },
    searchTerm: function () {
      this.$store.dispatch('menu/searchTerm', this.terms)
    },
    logout() {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$store.commit('reset');
          this.$router.replace('/auth/login');
        })
        .catch(err => {
          console.error("Error occured when logging" + err)
        })
    },
  },
  components: {
    SettingsSelection,
    Notification,
    NotificationBell: () => import('vue-notification-bell'),
    ClubsSelection: () => import('@custom/core/ClubsSelection'),
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/menu/_header-menu.scss";
</style>

