import store from "@/plugins/store";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
export const ROLE_USER = 'ROLE_USER';
export const ROLE_CLUB = 'ROLE_CLUB';
export const ROLE_CLUB_NETWORK_OWNER = 'ROLE_CLUB_NETWORK_OWNER';

export const currentRoles = () => {
  return store.getters["auth/currentUser"].roles;
}

export const hasRole = (role) => {
  return isNotUndefinedAndNotNull(store.getters["auth/currentUser"].roles.find(el => el === role));
}
