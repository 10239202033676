<template>
  <div
      class="parent"
      :style="svgStyle"
      v-html="icon"
  >
  </div>
</template>
<script>
  export default {
    props: {
      icon: {
        type: String,
        default: ''
      },
      svgIconStyle: {
        type: Object,
        default: () => {
          return {
            fill: '#015973',
            stroke : '#015973'
          }
        }
      }
    },
    data: () => ({
      svgStyle: null,
    }),
    created () {
      this.svgStyle = {
        '--fill' : this.svgIconStyle.fill,
        '--stroke' : this.svgIconStyle.stroke,
      }
    }
  }
</script>
<style scoped>
/deep/ .st0 {
  fill: var(--fill);
  stroke: var(--stroke);
}

/deep/ svg path{
  fill: var(--fill);
  stroke: var(--stroke);
}

</style>
