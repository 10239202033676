export default {
  setPlaygrounds: (state, payload) => {
    state.playgrounds = payload;
  },
  setActivities: (state, payload) => {
    state.activities = payload;
  },
  setDate: (state, payload) => {
    state.date = payload;
  },
  setOperations: (state, payload) => {
    state.operations = payload;
  },
  addBlocksByDate: (state, payload) => {
    state.blocksByDate.push(payload);
  },
  resetBlocksByDate: (state) => {
    state.blocksByDate = [];
  },
  addOperation: (state, operation) => {
    state.operations.push(operation);
  },
  addActivity: (state, activity) => {
    state.activities.push(activity);
  },
  removeActivity: (state, activity) => {
    state.activities.splice(state.activities.indexOf(activity), 1);
  },
  removeOperation: (state, operation) => {
    setTimeout(() => {
      const operationToRemove = state.operations.find(el => el.id === operation.id);

      if ("undefined" !== typeof operationToRemove) {
        state.operations.splice(state.operations.indexOf(operationToRemove), 1);
      }
    }, 2500);
  }
}
