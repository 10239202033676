import Vue from "vue";
import {
  currentTimezone, diffUtc,
  toTimeFormat,
  toTimezone,
  toTimezoneCustomFormat,
  toUtc,
  toUtcCustomFormat
} from "@/utils/timezone";

const timezonePlugin = {
  dateToUtc(date) {
    return toUtc(date);
  },
  dateToTimezone(date) {
    return toTimezone(date);
  },
  dateToTimeFormat(date) {
    return toTimeFormat(date);
  },
  dateToTimezoneCustomFormat(payload) {
    return toTimezoneCustomFormat(payload.date, payload.format);
  },
  dateToUtcCustomFormat(payload) {
    return toUtcCustomFormat(payload.date, payload.format);
  },
  diffDateFormat(payload) {
    return diffUtc(payload.endingDate, payload.startingDate, payload.format);
  },

  install (Vue, options) {
    Vue.prototype.$currentTimezone = currentTimezone();
    Vue.prototype.$toUtc = this.dateToUtc;
    Vue.prototype.$diffUtc = this.diffDateFormat;
    Vue.prototype.$toTimezone = this.dateToTimezone;
    Vue.prototype.$toTimeFormat = this.dateToTimeFormat;
    Vue.prototype.$toUtcCustomFormat = this.dateToUtcCustomFormat;
    Vue.prototype.$toTimezoneCustomFormat = this.dateToTimezoneCustomFormat;
  },
}

Vue.use(timezonePlugin);

export default timezonePlugin;
