<template>
  <div
    :key="'sidebar-' + componentKey"
    :class="'app_' + $store.getters['auth/currentClub'].id"
    class="sidebar-shadow"
  >
    <!-- Page Sidebar Start-->
    <div class="iconcolor-sidebar">
      <div
        class="sidebar custom-scrollbar"
      >
        <ul
          class="sidebar-menu"
          v-click-outside="hideAllSubMenus"
        >
          <li
            class="left-arrow"
            :class="{'d-none': layout.settings.layout_type=='rtl'? hideLeftArrowRTL: hideLeftArrow}"
            @click="(layout.settings.sidebar.type === 'horizontal_sidebar' && layout.settings.layout_type==='rtl') ? scrollToLeftRTL() : scrollToLeft()"
          >
            <i class="fa fa-angle-left"></i>
          </li>
          <li class="logo-wrap">

            <div
              v-if="$store.getters['layout/getInnerWidth'] > 761"
              class="pointer d-flex justify-content-center"
              @click="goToPlanning()"
            >
              <img
                src="../../assets/images/logo/doinsport-logo.svg"
                class="doinsport-logo"
                width="30"
                height="30"
                alt
              />
            </div>
          </li>
          <ul
            id="sidebar-id"
            class="sidebar-menu list-menu sidebar-scroller"
            :class="isOpen === true ? 'active-menu': 'closed-menu '"
          >

            <li
              v-for="(menuItem, index) in menuItems"
              v-if="hasEnzoCardAccess(menuItem)"
              :key="index"
              :class="isActiveMenu(menuItem) ? 'active-li': ''"
              class="li-side-menu li-side-mobile"
              @mouseover="menuItem.size= 30"
              @mouseleave="menuItem.size = 27;"
            >
              <dropmenu-sidebar
                v-if="menuItem.subMenu && menuItem.options.length > 0"
                :key="'sideBar' + index"
                :title="$t(menuItem.tooltip)"
                :options="menuItem.options"
                @on:hide="hideAllSubMenus"
                @on:hide-mobile="hideMobileMenu"
              />
              <a
                v-if="menuItem.type === 'link'"
                class="sidebar-header p-2 pointer"
                @click="show(menuItem)"
              >
                <img
                  :width="23"
                  :src="(!isActiveMenu(menuItem) && menuItem.size === 27 ) ? require(`@/assets/icons/sidebar/default/${menuItem.icon}.svg`) : require(`@/assets/icons/sidebar/hover/${menuItem.icon}.svg`) "
                />
                <i
                  v-if="menuItem.children"
                  class="fa fa-angle-right pull-right"
                ></i>
                <span
                  class="icon-sub-label mb-1 mt-1"
                  :class="(!isActiveMenu(menuItem) && menuItem.size === 27 ) ? 'inactive-menu-text': 'active-menu-text'"
                >
                  {{ $t(menuItem.tooltip) }}
                </span>
              </a>
            </li>
          </ul>
          <li
            class="right-arrow"
            :class="{'d-none': layout.settings.layout_type=='rtl'? hideRightArrowRTL : hideRightArrow }"
            @click="(layout.settings.sidebar.type == 'horizontal_sidebar' && layout.settings.layout_type=='rtl') ? scrollToRightRTL() : scrollToRight()"
          >
            <i class="fa fa-angle-right"></i>
          </li>
        </ul>
      </div>
    </div>
    <!-- Page Sidebar Ends-->
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: "Sidebar",
  components: {
    DropmenuSidebar: () => import('./dropmenu-sidebar')
  },
  data() {
    return {
      width: 0,
      componentKey: 0,
      height: 0,
      equalPath: '/planning',
      margin: 0,
      hideRightArrowRTL: true,
      hideLeftArrowRTL: true,
      hideRightArrow: true,
      hideLeftArrow: true,
      menuWidth: 0,
      menuOptions: [],
      isOpen: false
    };
  },
  computed: {
    ...mapState({
      menuItems: state => state.menu.data,
      layout: state => state.layout.layout,
      sidebar: state => state.layout.sidebarType
    }),
    hasEnzoCardConfig() {
      return this.$store.getters['auth/currentClub'].accessControlService;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.initActiveMenu();

    this.handleResize();

    if (this.width < 991) {
      this.layout.settings.sidebar.type = "default";
    }

    const val = this.sidebar
    if (val == 'default') {
      this.layout.settings.sidebar.type = 'default';
      this.layout.settings.sidebar.body_type = 'default';
    } else if (val == 'compact') {
      this.layout.settings.sidebar.type = 'compact-wrapper';
      this.layout.settings.sidebar.body_type = 'sidebar-icon';
    } else if (val == 'compact-icon') {
      this.layout.settings.sidebar.type = 'compact-page';
      this.layout.settings.sidebar.body_type = 'sidebar-default';
    } else if (val == 'horizontal') {
      this.layout.settings.sidebar.type = 'horizontal_sidebar';
      this.layout.settings.sidebar.body_type = '';
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.$bus.$on('refresh-sidebar', () => {
      this.componentKey++;
    });
    this.setFilters();

    document.getElementById('canvas-bookmark').onclick = (e) => {
      let sideBarEl = document.getElementById('sidebar-id');
      if (e.target !== sideBarEl) {
        const burgerId = document.getElementById('burger-icon-id');

        if (e.target === burgerId) {
          this.isOpen = !this.isOpen;
          this.sendData();
        } else {
          if (this.hasOpenSubMenus()) {
            this.isOpen = true;
            this.sendData();
          } else {
            this.isOpen = false;
            this.sendData();
          }
        }
      } else {
        this.isOpen = true;
        this.sendData();
      }
    }
  },
  methods: {
    hasEnzoCardAccess(menuItem) {
      if (menuItem.title === 'views.live-accesses.index.menu') {
        return this.hasEnzoCardConfig;
      }
      return true;
    },
    sendData() {
      this.$emit('on:send-data', this.isOpen)
    },
    hideMobileMenu() {
      this.isOpen = false;
    },
    hideAllSubMenus() {
      for (const menu of this.menuItems) {
        menu.subMenu = false;
      }
    },
    hasOpenSubMenus() {
      for (const menu of this.menuItems) {
        if (menu.subMenu === true) {
          return true;
        }
      }
      return false;
    },
    goToPlanning() {
      if (this.$router.currentRoute.name !== 'planning') {
        this.$router.push('/planning');
        this.componentKey++;
      }
    },
    setFilters() {
      this.menuItems.filter(items => {
        if (items.path === this.$route.path)
          this.$store.dispatch("menu/setActiveRoute", items);
        if (!items.children) return false;
        items.children.filter(subItems => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch("menu/setActiveRoute", subItems);
          if (!subItems.children) return false;
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch("menu/setActiveRoute", subSubItems);
          });
        });
      });
    },
    isActiveMenu(menuItem) {
      if (this.$router.currentRoute.path.includes(menuItem.path) && menuItem.active) {
        return true;
      } else if (menuItem.options.length > 0 && menuItem.active) {
        return true;
      }
      return false;
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setNavActive", item);
    },
    getMainHeaderLeftStyle() {
      return 'height:100px';
    },
    handleResize() {
      this.width = window.innerWidth - 310;
    },
    initActiveMenu() {
      for (const menu of this.menuItems) {
        menu.active = (menu.path === this.$router.currentRoute.path);
      }
    },
    scrollToRightRTL() {
      this.temp = this.width + this.margin;
      // Checking condition for remaing margin
      if (this.temp === 0) {
        this.margin = this.temp;
        this.hideRightArrowRTL = true;
      }
      // else
      else {
        this.margin += this.width;
        this.hideRightArrowRTL = false;
        this.hideLeftArrowRTL = false;
      }
    },
    scrollToLeftRTL() {
      // If Margin is reach between screen resolution
      if (this.margin <= -this.width) {
        this.margin += -this.width;
        this.hideLeftArrowRTL = true;
      }
      //Else
      else {
        this.margin += -this.width;
        this.hideRightArrowRTL = false;
      }
    },
    show(menuItem) {
      for (const menu of this.menuItems) {
        if (menu.path === menuItem.path) {
          menu.active = true;
          menu.subMenu = true;
          menu.size = 30;
        } else {
          menu.active = false;
          menu.subMenu = false;
          menu.size = 27;
        }
      }
      if (menuItem.options.length === 0) {
        if (this.$router.currentRoute.path !== menuItem.path) {
          this.isOpen = false;
          this.$router.push(menuItem.path);
          this.hideAllSubMenus();
        }
      }
    },
    scrollToLeft() {
      // If Margin is reach between screen resolution
      if (this.margin >= -this.width) {
        this.margin = 0;
        this.hideLeftArrow = true;
      }
      //Else
      else {
        this.margin += this.width;
        this.hideRightArrow = false;
      }
    },
    scrollToRight() {
      this.temp = this.menuWidth + this.margin;
      // Checking condition for remaing margin
      if (this.temp < this.menuWidth) {
        this.margin = -this.temp;
        this.hideRightArrow = true;
      }
      // else
      else {
        this.margin += -this.width;
        this.hideLeftArrow = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@lazy/menu/_sidebar-menu.scss";

.inherited-display {
  display: inherit;
}

.icon-sub-label {
  color: #707070;
  text-decoration: none;
  font-size: 14px;
  font-family: Avenir;
}

.a:hover {
  color: red;
}

.sidebar-header:hover {
  color: red;
}

.inactive-menu-text {
  color: #707070;
}

.active-menu-text {
  color: red;
}

.doinsport-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sidebar-shadow {
  box-shadow: 30px 4px 16px 0px rgba(226, 225, 230, 0.1);
}

.d-content-height {
  display: none;
}


</style>
