<template>
  <d-side-bar
    :id="modalId"
    :model="display"
    :identifier="'checkoutModal' + modalId"
    :right-position="true"
    header-class="header-modal-checkout"
    width="500px"
    @shown="$emit('shown')"
    @hidden="beforeHide"
    @change="$emit('change')"
  >
    <div
      v-if="customer"
      slot="header"
      style="padding: 10px"
    >
      <b-row>
        <b-col cols="10">
          <div class="modal-name">
            {{ $t('views.client.details.body.left.subscription-affect.transaction-details') }}
          </div>
          <span>{{ customer.firstName }} {{ customer.lastName }}</span>
        </b-col>
        <b-col cols="2" style="display: flex; align-items: center">
          <div style="margin: auto;" v-on:click="$store.dispatch('nextore/clearSale')"><i class="fa fa-trash"></i></div>

        </b-col>
      </b-row>
    </div>
    <div slot="body" class="p-4">
      <transaction-details class="flex-row" />
    </div>
    <div
      slot="footer"
    >
      <div
        class="footer-section"
      >

        <numeric-input
          :show-pave="true"
          :selected-payment-method="selectedPaymentMethod"
        />
        <div class="border-bottom-grey"/>
        <b-row>
          <b-col class="col-divider-6">
            <b-row>
              <b-col class="ml-3 total-amount mt-1" cols="12">
                {{ $t('views.client.details.body.left.subscription-affect.total-rate') }}
              </b-col>
              <b-col align="right" class="price-total" cols="12">
                {{ parseFloat($store.getters["nextore/getTotalPrices"]).toFixed(2) }} {{ $currency }}
              </b-col>
            </b-row>
          </b-col>
          <div
            class="split-layout__divider"
          >
            <div class="split-layout__rule"></div>
            <div class="split-layout__rule"></div>
          </div>
          <b-col
            class="col-divider-5"
          >
            <b-row>
              <b-col class="total-amount mt-1" cols="12">
                {{ paymentLabel }}
              </b-col>
              <b-col
                cols="12"
                class="pr-4"
                align="right"
                :class="restToPay <= 0 ? 'rest-to-give-back' : 'rest-to-pay'"
              >
                {{ restToPay <= 0 ? parseFloat($store.getters["nextore/getRendu"]).toFixed(2) : parseFloat(Math.abs(restToPay)).toFixed(2) }} {{ $currency }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="border-bottom-grey"/>
        <div class="d-flex mt-3 ml-2 mr-2">
          <div
            :class="isValidated ? 'payment-icon' : 'disabled'"
            @click="onPaymentMethodClick('card')"
          >
            <div class="d-flex justify-content-center">
              <i
                :class="selectedPaymentMethod.method === 'card' ? 'active-icon' : ''"
                class="fa-2x fa fa-credit-card-alt"
              ></i>
            </div>
            <div
              :class="selectedPaymentMethod.method === 'card' ? 'active-label' : 'icon-label'"
            >
              {{ $t('views.client.details.body.left.subscription-affect.cb') }}
            </div>
          </div>
          <div
            :class="isValidated ? 'payment-icon' : 'disabled'"
            class="margin-auto"
            @click="onPaymentMethodClick('cash')"
          >
            <div>
              <div class="d-flex justify-content-center">
                <i
                  :class="selectedPaymentMethod.method === 'cash' ? 'active-icon' : ''"
                  class="fa-2x fa fa-money"
                ></i>
              </div>
              <div
                :class="selectedPaymentMethod.method === 'cash' ? 'active-label' : 'icon-label'"
              >
                {{ $t('views.client.details.body.left.subscription-affect.cash') }}
              </div>
            </div>
          </div>
          <div
            :class="isValidated ? 'payment-icon' : 'disabled'"
            @click="onPaymentMethodClick('check')"
          >
            <div class="d-flex justify-content-center">
              <i
                :class="selectedPaymentMethod.method === 'check' ? 'active-icon' : ''"
                class="fa-2x icofont icofont-law-document"
              ></i>
            </div>
            <div
              :class="selectedPaymentMethod.method === 'check' ? 'active-label' : 'icon-label'"
            >
              {{ $t('views.client.details.body.left.subscription-affect.check') }}
            </div>
          </div>
        </div>
        <div class="d-flex mt-3 ml-2 mr-2 mb-3">
          <div
            class="margin-auto payment-icon"
            @click="onPaymentMethodClick('alma')"
          >
            <div class="d-flex justify-content-center">
              <i
                :class="selectedPaymentMethod.method === 'sport-ticket' ? 'active-icon' : ''"
                class="fa-2x icofont icofont-coins"
              ></i>
            </div>
            <div
              :class="selectedPaymentMethod.method === 'sport-ticket' ? 'active-label' : 'icon-label'"
            >
              {{ $t('views.client.details.body.left.subscription-affect.alma') }}
            </div>
          </div>
          <div
            class="margin-auto payment-icon"
            @click="onPaymentMethodClick('ancv')"
          >
            <div>
              <div class="d-flex justify-content-center">
                <i
                  :class="selectedPaymentMethod.method === 'wallet' ? 'active-icon' : ''"
                  class="fa-2x fa fa-google-wallet"
                ></i>
              </div>
              <div
                :class="selectedPaymentMethod.method === 'wallet' ? 'active-label' : 'icon-label'"
              >
                ANCV
              </div>
            </div>
          </div>
          <div
            class="margin-auto payment-icon"
            @click="onPaymentMethodClick('sport')"
          >
            <div class="d-flex justify-content-center">
              <i
                :class="selectedPaymentMethod.method === 'other' ? 'active-icon' : ''"
                class="fa-2x fa fa-ellipsis-h"
              ></i>
            </div>
            <div
              :class="selectedPaymentMethod.method === 'other' ? 'active-label' : 'icon-label'"
            >
              Coupon Sport
            </div>
          </div>
        </div>
        <div v-click-outside="onClick">
          <d-button
            :text="keyboardText"
            class="border-radius-0 pt-4 pb-4 d-btn-sm btn d-btn-primary font-text-payment btn-full-width"
            @on:button-click="onKeyboardBtnClick"
          />
        </div>
      </div>
    </div>
  </d-side-bar>
</template>

<script>
import {SUCCESS, DANGER} from "@plugins/flash";
import {addSale} from "@api/nextore/services/sale.api";
import { isNotUndefinedAndNotNull } from "@/utils/classes";
import {randomString} from "@/utils/form";
import {floatToFixed, toFloatFixed} from "@/utils/string";

const MODAL_ID = 'CHECKOUT_ID';

export default {
  data: () => ({
    showPave: true
  }),
  components: {
    NumericInput: () => import('./NumericInput'),
    TransactionDetails: () => import('./details/Index')
  },
  props: {
    selectedRate: {
      type: Object,
      default: () => ({
        values: null,
        selected: false,
      })
    },
    selectedPaymentMethod: {
      type: Object,
      default: () => ({
        values: [],
        method: null,
        value: 0,
        selected: false,
      }),
    },
    selectedSubscription: {
      type: Object,
      default: () => {
      },
    },
    display: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: MODAL_ID,
    }
  },
  computed: {
    customer() {
      return this.$store.getters["nextore/getCustomer"];
    },
    isValidated() {
      return (this.$store.getters["nextore/getRestToPay"] > 0);
    },
    products() {
      return this.$store.getters["nextore/getProducts"];
    },
    keyboardText() {
      if (!this.isValidated) {
        return 'general.actions.dismiss';
      } else {
        return 'general.actions.validate';
      }
    },
    paymentLabel() {
      if (this.restToPay < 0 || this.$store.getters["nextore/getRendu"] > 0) {
        return this.$t('views.client.details.body.left.subscription-affect.rest-to-give-back')
      } else {
        return this.$t('views.client.details.body.left.subscription-affect.rest-to-pay')
      }
    },
    restToPay() {
      return this.$store.getters["nextore/getRestToPay"];
    }
  },
  methods: {
    onKeyboardBtnClick() {
      return !this.isValidated ? this.$emit('hidden') : this.$emit('hidden');
    },
    onHide() {
      this.showPave = false;
    },
    onClick() {
      this.showPave = false;
    },
    onPaymentMethodClick(method) {
      this.$store.dispatch("nextore/addPayment", {method, value: 0});
      if (this.$store.getters["nextore/getRestToPay"] <= 0) {
        this.proceedToPayment();
      }
    },
    beforeHide () {
        this.$store.dispatch("nextore/setRendu", 0);
        this.$emit('hidden');
    },
    proceedToPayment() {
      const products = this.$store.getters["nextore/getProducts"];
      const payments = this.$store.getters["nextore/getPayments"];

      let sale = {
        "info_cmd": {
          "customer_id": this.customer ? this.customer.nextoreCustomerReference + '' : null,
          "customer": this.customer ? this.customer.fullName : null,
          "staff_note": "",
        }
      }
      let totalPayment = 0;
      payments.forEach(payment => {
        totalPayment = totalPayment + parseFloat(payment.value);
        const amount = floatToFixed(payment.value, 2) + '';

        switch (payment.method) {
          case 'ancv': {
            sale ['paieid-' + randomString()] = {
              type: 'CHEQUE VACANCES',
              value: amount,
              note: ""
            }
            break;
          }
          case 'sport': {
            sale ['paieid-' + randomString()] = {
              type: 'COUPON SPORT',
              value: amount,
              note: ""
            }
            break;
          }
          case 'card': {
            sale ['paieid-' + randomString()] = {
              type: 'CARTE BANCAIRE',
              value: amount,
              note: ""
            }
            break;
          }
          case 'cash': {
            sale ['paieid-' + randomString()] = {
              type: 'ESPECES',
              value: amount,
              note: ""
            }
            break;
          }
          case 'check': {
            sale ['paieid-' + randomString()] = {
              type: 'CHEQUE',
              value: amount,
              note: ""
            }
            break;
          }
          case 'sport-ticket': {
            sale ['paieid-' + randomString()] = {
              type: 'COUPON SPORT',
              value: amount,
              note: ""
            }
            break;
          }
          case 'alma': {
            sale ['paieid-' + randomString()] = {
              type: 'ALMA',
              value: amount,
              note: ""
            }
            break;
          }
        }
      });

      let totalAmount = 0;
      products.forEach(product => {
        totalAmount = totalAmount + ( parseFloat(product.price) * parseFloat(product.quantity));
        const amount = floatToFixed(product.price, 2) + '';
        sale ['prodid-' + randomString()] = {
          "id": product.id + '',
          "name": product.name,
          "price": amount + '',
          "quantity": product.quantity + '',
        }
      });

      // const rendu = totalPayment - totalAmount;
      // this.$store.dispatch("nextore/setRendu", rendu);

      addSale(sale)
        .then((response) => {
          if (response.data !== 'false') {
            this.$flash(null, this.$t('general.actions.success-sale'), 5000, SUCCESS);
            this.$store.dispatch('nextore/clearSale');
            if (this.$store.getters["nextore/rendu"] <= 0) {
              this.$emit('hidden');
            }
            this.restToPay = 0 - this.$store.getters["nextore/rendu"];
          } else {
            this.$flash(null, this.$t('general.actions.error-notification'), 5000, DANGER);
            // TODO : manage error
          }
        });
    }
  },
}
</script>
<style scoped lang="scss">
/deep/ .header-modal-checkout {
  background-color: #ffffff;
  padding-bottom: 20px;
  border-bottom: 2px solid #EBF2F7;
}


.modal-name {
  padding-top: 15px;
  font: normal normal 900 18px Avenir;
}
.payment-type {
  padding-left: 1.5rem;
  font: normal normal normal 15px Avenir;
}

.product-item {
  font: normal normal normal 15px Avenir;
}

.customer-name {
  font-weight: 700;
}

/deep/ .close-icon {
  display: none;
}

.width-auto {
  width: -webkit-fill-available;
}

.footer-section {
  position: absolute;
  bottom: 60px;
  width: 100%;
  height: fit-content;
}

.border-radius-0 {
  border-radius: 0;
}

.total-amount {
  font: normal normal bold 25px Avenir;
  letter-spacing: -0.33px;
  color: #2E2E2E;
}

.icon-label {
  font: normal normal normal 17px Avenir;
  letter-spacing: -0.33px;
  color: #2E2E2E;

  &:hover {
    color: #092772;
  }
}

.active-label {
  font: normal normal bold 17px Avenir;
  letter-spacing: -0.33px;
  color: #092772;
}

.active-icon {
  color: #092772;
}

.price-total {
  font: normal normal bold 25px Avenir;
  letter-spacing: -0.47px;
  color: #092772;
}

.rest-to-pay {
  font: normal normal bold 25px Avenir;
  letter-spacing: -0.47px;
  color: #c80523;
  padding-right: 40px;
}

.rest-to-give-back {
  font: normal normal bold 25px Avenir;
  letter-spacing: -0.47px;
  color: #4eb009;
  padding-right: 40px;
}

.col-divider-6 {
  flex: 0 0 54%;
  max-width: 54%;
}

.payment-icon {
  cursor: pointer;
  width: calc(100% / 3);
  text-align: center;
  &:hover {
    color: #092772;
  }
  &.disabled {
    cursor: unset;
  }
}

.font-text-payment {
  font: normal normal bold 18px Avenir;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-row {
  max-height: calc(100vh - 520px);
  overflow-y: scroll;
}
</style>
