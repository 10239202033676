<template>
  <div :class="'app_' + $store.getters['auth/currentClub'].id">
    <div id="canvas-bookmark" class="page-wrapper" :class="layout.settings.sidebar.type">
      <div :class="layout.settings.sidebar.body_type">
        <div
        >

        </div>
        <div class="page-body">
          <transition name="fadeIn" enter-active-class="animated fadeIn">
            <router-view
              id="view-id"
              class="view"
            ></router-view>
          </transition>
        </div>
      </div>
    </div>

    <checkout-modal
      :key="'modal-sale'"
      :display="showModal"
      @hidden="onHide"
    />

    <div v-if="showOpenModalBtn" class="icon-caisse-modal" @click="openCheckoutModal()">
      <img
        :src="require('@/assets/caisse.svg')"
        class="pointer ml-2 mr-2"
        width="20px"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Header from './header'
import Sidebar from './sidebar'
import CheckoutModal from "@views/Nextore/CheckoutModal";

export default {
  name: 'mainpage',
  data() {
    return {
      display: false,
      rightsidebar_toggle_var: false,
      mobileheader_toggle_var: false,
      sidebar_toggle_var: false,
      horizontal_Sidebar: true,
      resized: false
    }
  },
  // props:['sidebar_toggle_var'],
  components: {
    CheckoutModal,
    Header,
    Sidebar,
  },
  computed: {
    showModal() {
      return this.$store.getters["nextore/showModal"];
    },
    showOpenModalBtn() {
      return this.$store.getters["nextore/getProducts"] ? (this.$store.getters["nextore/getProducts"].length > 0) : false;
    },
    ...mapState({
      menuItems: state => state.menu.data,
      layout: state => state.layout.layout,
      transparentbg: state => state.layout.transparentbg
    }),
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
    this.resized = this.sidebar_toggle_var;
    this.$store.dispatch('layout/set')
  },
  watch: {
    '$route'() {
      this.menuItems.filter(items => {
        if (items.path === this.$route.path)
          this.$store.dispatch('menu/setActiveRoute', items)
        if (!items.children) return false
        items.children.filter(subItems => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch('menu/setActiveRoute', subItems)
          if (!subItems.children) return false
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch('menu/setActiveRoute', subSubItems)
          })
        })
      })
    },
    sidebar_toggle_var: function () {
      this.resized = (this.width <= 991) ? !this.sidebar_toggle_var : this.sidebar_toggle_var
    }
  },
  methods: {
    onHide() {
      this.$store.dispatch('nextore/showModal', false);
    },
    openCheckoutModal() {
      this.$store.dispatch('nextore/showModal', true);
    },
    getData(data){
      const bodyEl = document.getElementById('view-id');

      if(this.$store.getters['layout/getInnerWidth'] < 760){
        if(data === true){
          bodyEl.classList.add('add-margin')
        }
        else{
          bodyEl.classList.remove("add-margin")
        }
      }
    },
    sidebar_toggle(value) {
      this.sidebar_toggle_var = !value
    },
    rightsidebar_toggle(value) {
      this.rightsidebar_toggle_var = value
    },
    mobiletoggle_toggle(value) {
      this.mobileheader_toggle_var = value
    },
    handleResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/_body.scss";


.icon-caisse-modal {
  background-color: #0b1620;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  box-shadow: 0 0 10px 10px rgba(145, 145, 145, 0.3);
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px;
  img {
    margin: 0!important;
    display: block;
    text-align: center;
    color: white;
    width: 100%;
    height: auto;
    &path {
      fill: white;
    }
  }
}
</style>
