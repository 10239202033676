import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

export const USER_CLUB_URL = '/user-clubs';

export const getClubCoachs = (itemsPerPage, page, search ='', filters = '') =>
  _get(
    USER_CLUB_URL +
    '?clubs.id=' + clubId +
    '&roles=ROLE_CLUB_COACH'+
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page + '&search=' + search + filters
  )
;

export const postUserClub = (userClub) => _post(USER_CLUB_URL, userClub);

export const putUserClub = (userClub) => _put(USER_CLUB_URL + '/' + userClub.id, userClub);

export const deleteUserClub = (userClubId) => _delete(USER_CLUB_URL + '/' + userClubId);
