import Vue from "vue";

import Vuesax from 'vuesax';

import 'vuesax/dist/vuesax.css';
import 'material-icons/iconfont/material-icons.css';


Vue.use(Vuesax, {});

export default Vuesax;
