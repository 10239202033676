export const ARRAY_BUFFER = 'arraybuffer';
export const BINARY_ENCODE= 'binary';
export const PDF_CONTENT_TYPE = 'application/pdf';
export const JSON_CONTENT_TYPE = 'application/json';

export default {
  pdf_contentType: PDF_CONTENT_TYPE,
  json_contentType: JSON_CONTENT_TYPE,
  binary_encode: BINARY_ENCODE,
  array_buffer: ARRAY_BUFFER,
}

