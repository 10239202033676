import store from "@/plugins/store";

export const currentCurrency = () => {
  return store.getters["auth/currentClub"].currency;
}

export const currency = () => {
  return store.getters["currency/getCurrency"][currentCurrency()]
}

export const toPrice = (value) => {
  return (value.includes(currency)) ? parseFloat(value.split(currency)[0]).toFixed(2) : parseFloat(value).toFixed(2);
}
