import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const domains = [
  {
    prefix: 'D',
    requireComponent: require.context(
      '@/components/doinsport', true, /\.vue$/
    ),
  },
];

for (const domain of domains) {
  domain.requireComponent.keys().forEach(fileName => {
    const componentConfig = domain.requireComponent(fileName);
    const componentName = upperFirst(
      camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
    );

    Vue.component(`${domain.prefix}${componentName}`, componentConfig.default || componentConfig);
  });
}
