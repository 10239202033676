<template>
  <div>
    <div
      class="table-responsive datatable-vue"
      :class="overflowEnabled ? 'table-responsive-overflow' : ''"
    >
      <b-table
        show-empty
        stripe
        class="dataTable"
        no-border-collapse
        :id="id"
        :thead-class="customView ? 'hidden_header' : ''"
        :sticky-header="stickyHeader"
        :hover="false"
        :borderless="true"
        :style="cssVars"
        :bordered="false"
        :items="items"
        :busy="isBusy"
        :empty-text="$t(emptyText)"
        :empty-filtered-text="$t('components.doinsport.table.empty-filtred-text')"
        :fields="tablefields"
        :current-page="currentPage"
        :per-page="perPageCurrent"
        @row-clicked="onRowClick"
        @sort-changed="onSortClick"
      >
        <template v-slot:table-busy>
          <busy-table/>
        </template>
        <template v-if="hasCheckbox" v-slot:head(preview)="data">
          <head-check-box/>
        </template>
        <template v-slot:head(iconLabel)="data">
          <head-icon-label
            :custom-data="data"
          />
        </template>
        <template v-slot:head(collapseLabel)="data">
          <head-collapse-label
            :custom-data="data"
            @on:icon-click="$emit('on:icon-click', data)"
          />
        </template>
        <template v-if="hasPhoto" v-slot:head(photo)="data">
          <head-photo-label
            :label="data.label"
          />
        </template>
        <template v-slot:cell(earliestStartAtSubscription)="row">
          <cell-subscription-start-at
            :subscription-cards-available="row.item.subscriptionCardsAvailable"
          />
        </template>
        <template v-slot:cell(latestEndAtSubscription)="row">
          <cell-subscription-end-at
            :subscription-cards-available="row.item.subscriptionCardsAvailable"
          />
        </template>
        <template v-slot:cell(participantAvatar)="row">
          <cell-planning-participant-avatar
            :photo="row.item.participantAvatar"
          />
        </template>
        <template v-slot:cell(historyPaymentTokenCreditValue)="row">
          <cell-client-history-payment-token-credit-value
            :value="row.item.historyPaymentTokenCreditValue"
          />
        </template>
        <template v-slot:cell(confirmedParticipation)="row">
          <cell-planning-participant-confirmed
            :participant="row.item"
          />
        </template>
        <template v-slot:cell(bookingOwner)="row">
          <cell-participant-owner
            :participant="row.item"
          />
        </template>
        <template v-slot:cell(collapseLabel)="row">
          <cell-collapse-label
            :label="row.item.collapseLabel"
            @on:check="$emit('on:check', row.item)"
          />
        </template>
        <template v-slot:cell(participantPaymentStatus)="row">
          <cell-planning-participant-payment-status
            :participantPayment="row.item.participantPaymentStatus"
          />
        </template>
        <template v-slot:cell(fields)="row">
          <cell-parameters-forms-fields
            :fields="row.item.fields"
          />
        </template>
        <template v-slot:cell(compaignStatus)="row">
          <cell-compaign-status
            :compaign-status="row.item.compaignStatus"
          />
        </template>
        <template v-slot:cell(rulesSubscriptionPlans)="row">
          <cell-rules-subscription-plans
            :subscription-plans="row.item.clubSubscriptionPlans"
          />
        </template>
        <template v-slot:cell(bookingParticipants)="row">
          <cell-booking-participants
            :booking="row.item.bookingParticipants"
          />
        </template>
        <template v-slot:cell(participantPresence)="row">
          <cell-participant-presence
            :booking="row.item.participantPresence"
          />
        </template>
        <template v-slot:cell(bookingPayment)="row">
          <cell-booking-payment
            :booking="row.item.bookingPayment"
          />
        </template>
        <template v-slot:cell(bookingActions)="row">
          <cell-booking-actions
            :booking="row.item.bookingActions"
          />
        </template>
        <template v-slot:cell(bookingTimetableBlockPrice)="row">
          <cell-booking-timetable-block-price
            :booking="row.item.bookingTimetableBlockPrice"
          />
        </template>
        <template v-slot:cell(rulesTimeAttendanceId)="row">
          <cell-rules-category
            :rules-time-attendance-id="row.item.timeAttendanceId"
          />
        </template>
        <template v-slot:cell(rulesAreaIds)="row">
          <cell-rules-area-ids
            :area-ids="row.item.areaIds"
          />
        </template>
        <template v-slot:cell(campaignSendingValue)="row">
          <cell-campaign-value
            :custom-data="row.item.campaignSendingValue"
          />
        </template>
        <template
          v-slot:cell(preview)="row"
        >
          <cell-preview-check-box
            :photo="row.item.preview"
            :values="row.item"
          />
        </template>
        <template
          v-slot:cell(customerAccessPreview)="row"
        >
          <cell-preview-check-box
            :photo="row.item.customerAccessPreview"
            :values="row.item"
          />
        </template>
        <template v-slot:cell(partcipantPreview)="row">
          <cell-participant-preview
            :photo="row.item.partcipantPreview.photo"
            :values="row.item.partcipantPreview.values"
          />
        </template>
        <template v-slot:cell(about)="row">
          <cell-participant-about
            :participant="row.item"
          />
        </template>
        <template v-slot:cell(colToPay)="row">
          <cell-col-to-pay
            :colToPay="row.item.colToPay"
          />
        </template>
        <template v-slot:cell(colPaid)="row">
          <cell-col-paid
            :colPaid="row.item.colPaid"
            :colToPay="row.item.colToPay"
          />
        </template>
        <template v-slot:cell(photo)="row">
          <cell-preview
            :photo="row.item.photo"
            @on:upload-playground-photo="onPlaygroundPhotoUploaded($event, row.item)"
            @on:delete-playground-photo="$emit('on:delete-playground-photo', row.item)"
          />
        </template>
        <template v-slot:cell(availability)="row">
          <cell-playground-availability
            :availability="row.item.availability"
            @on:playground:enabled-change="$emit('on:playground:enabled-change', row.item)"
          />
        </template>
        <template v-slot:cell(status)="row">
          <cell-bookings-status
            :status="row.item.status"
          />
        </template>
        <template v-slot:cell(bookingStatus)="row">
          <cell-bookings-status
            :status="row.item"
          />
        </template>
        <template v-slot:cell(salesStatus)="row">
          <cell-sales-status
            :status="row.item"
          />
        </template>
        <template v-slot:cell(status)="row">
          <cell-sales-status
            :status="row.item.status"
          />
        </template>
        <template v-slot:cell(pay)="row">
          <cell-planning-actions
            :payment-data="row.item"
            :booking="row.item.booking"
            @on:detail_booking_reload="$emit('on:detail_booking_reload')"
          />
        </template>
        <template v-slot:cell(participant)="row">
          <cell-planning-delete
            :iri="row.item.iri"
            @on:row-remove="onRowRemove"
          />
        </template>
        <template v-slot:cell(block)="row">
          <cell-time-zone-block
            :block="row.item.block"
          />
        </template>
        <template v-slot:cell(paymentstatus)="row">
          <cell-payment-status :payment-status="row.item.paymentStatus"/>
        </template>
        <template v-slot:cell(participants)="row">
          <cell-events-participants
            :participants="row.item.participants"
          />
        </template>
        <template v-slot:cell(book)="row">
          <cell-events-book/>
        </template>
        <template v-slot:cell(bill)="row">
          <cell-payment-bill
            :invoice-url="row.item.bill"
          />
        </template>
        <template v-slot:cell(payoutReportRunUrl)="row">
          <cell-payout-bill
            :payout="row.item"
          />
        </template>
        <template v-slot:cell(payoutStatus)="row">
          <cell-payout-status
            :status="row.item.status"
          />
        </template>
        <template v-slot:cell(subscription)="row">
          <cell-client-subscription
            :subscription="row.item.subscription"
          />
        </template>
        <template v-slot:cell(singleSelection)="row">
          <cell-single-selection
            :row="row.item"
            @on:radio-button-check="onRadioCheck"
          />
        </template>
        <template v-slot:cell(singleSelectionRate)="row">
          <cell-single-selection-rate
            :row="row.item"
            @on:radio-button-rate-check="onRadioRateCheck"
          />
        </template>
        <template v-slot:cell(customEdit)="row">
          <cell-playground-custom-edit-button/>
        </template>
        <template v-slot:cell(plusIcon)="row">
          <cell-plus-icon
            :selection="row.item.plusIcon"
            @on:plus-icon-click="$emit('on:plus-icon-click', row.item)"
          />
        </template>
        <template v-slot:cell(minusIcon)="row">
          <cell-minus-icon
            :selection="row.item.minusIcon"
            @on:minus-icon-click="$emit('on:minus-icon-click', row.item)"
          />
        </template>
        <template v-slot:cell(labelWithDate)="row">
          <cell-label-date
            :label="row.item.labelWithDate.label"
            :date="row.item.labelWithDate.date"
          />
        </template>
        <template v-slot:cell(timetables)="row">
          <cell-playground-timetables
            :timetables="row.item.timetables"
          />
        </template>
        <template v-slot:cell(daysOfTheWeek)="row">
          <cell-days-of-the-week
            :days="row.item.daysOfTheWeek"
          />
        </template>
        <template v-slot:cell(actualityStatus)="row">
          <cell-news-status
            :status="row.item.actualityStatus"
          />
        </template>
        <template v-slot:cell(actions)="row">
          <cell-actions
            :item="row.item"
            :is-deleting="isDeleting"
            :custom-view="customView"
            @on:data-table-btn:delete="onCellActionClick($event, 'delete')"
            @on:data-table-btn:edit="onCellActionClick($event, 'edit')"
            @on:data-table-btn:pay="onCellActionClick($event, 'pay')"
            @on:data-table-btn:rar="onCellActionClick($event, 'rar')"
            @on:data-table-btn:unrar="onCellActionClick($event, 'unrar')"
            @on:data-table-btn:show="onCellActionClick($event, 'show')"
            @on:data-table-btn:details="onCellActionClick($event, 'details')"
          />
        </template>
        <template v-slot:cell(duration)="row">
          <cell-bookings-duration
            :startAt="row.item.startAt"
            :endAt="row.item.endAt"
          />
        </template>
        <template v-slot:cell(createdAt)="row">
          <cell-bookings-date
            :date="row.item.createdAt"
          />
        </template>
        <template v-slot:cell(fromDate)="row">
          <cell-from-date
            :from-date="row.item.fromDate"
          />
        </template>
        <template v-slot:cell(toDate)="row">
          <cell-to-date
            :to-date="row.item.toDate"
          />
        </template>
        <template v-slot:cell(startAt)="row">
          <cell-bookings-date
            :date="row.item.startAt"
          />
        </template>
        <template v-slot:cell(activity)="row">
          <cell-playground-activity
            :value="row.item.activity.name"
            :values="row.item.activity"
          />
        </template>
        <template v-slot:cell(playgrounds)="row">
          <cell-playgrounds-badge :playgrounds="row.item.playgrounds"/>
        </template>
        <template v-slot:cell(bookingPlaygrounds)="row">
          <cell-playgrounds-badge
            :playgrounds="row.item.playgrounds"
            :is-badged="false"
          />
        </template>
        <template v-slot:cell(playgroundBookingActivity)="row">
          <cell-playgrounds-badge
            :playgrounds="row.item.playgroundBookingActivity"
            :is-badged="false"
          />
        </template>
        <template v-slot:cell(toPay)="row">
          <cell-participant-to-pay
            :item="row.item"
            @on:discount-icon:click="$emit('on:discount-icon:click', row.item)"
            @on:undiscount-icon:click="$emit('on:undiscount-icon:click', row.item)"
          />
        </template>
        <template v-slot:cell(price)="row">
          <cell-price
            :price="row.item.price"
          />
        </template>
        <template v-slot:cell(regulation)="row">
          <cell-regulation
            :regulation="row.item.regulation"
          />
        </template>
        <template v-slot:cell(selection)="row">
          <cell-selection-checkbox
            :row="row.item"
            @on:checkbox-selected="onCheckboxSelectionSelected"
          />
        </template>
        <template v-slot:cell(otherPrices)="row">
          <cell-link
            :title="row.item.otherPrices"
            link-class="link-blue-light"
            @on:link-click="$emit('on:link-click', row.item)"
          />
        </template>
        <template v-slot:cell(qte)="row">
          <cell-custom
            :value="row.item.qte"
            class-name="text-dark-blue"
          />
        </template>
        <template v-slot:cell(calendarStatus)="row">
          <cell-playground-badge-status
            :row="row.item"
            @on:calendar-badge-update="$emit('on:calendar-badge-update', row.item)"
          />
        </template>
        <template v-slot:cell(pricePerParticipant)="row">
          <cell-custom
            :value="row.item.pricePerParticipant + currency"
          />
        </template>
        <template v-slot:cell(reservedBy)="row">
          <cell-client-reserved-by
            :item="row.item"
          />
        </template>
        <template v-slot:cell(recurringReservation)="row">
          <cell-bookings-recurring
            :item="row.item"
          />
        </template>
        <template v-slot:cell(bookingCategory)="row">
          <cell-bookings-category
            :item="row.item"
          />
        </template>
        <template v-slot:cell(paymentMethods)="row">
          <cell-payment-methods
            :paymentMethods="row.item.paymentMethods"
          />
        </template>
        <template v-slot:cell(userClientStepBookingDuration)="row">
          <cell-playground-duration
            :duration="row.item.userClientStepBookingDuration"
          />
        </template>
        <template v-slot:cell(creditPack)="row">
          <cell-credits-pack-items
            :items="row.item.items"
          />
        </template>
        <template v-slot:cell(userClubStepBookingDuration)="row">
          <cell-playground-duration
            :duration="row.item.userClubStepBookingDuration"
          />
        </template>
        <template v-slot:cell(availableDefault)="row">
          <cell-star
            :star-value="row.item.availableDefault"
          />
        </template>
        <template v-slot:cell(transactionDate)="row">
          <cell-custom
            :value="row.item.transactionDate"
            class-name="text-dark-blue"
          />
        </template>
      </b-table>
    </div>
    <div>
      <b-row
        v-if="showPagination"
        :class="$store.getters['layout/getInnerWidth'] > 800 ? 'mt-3 p-0' : 'mt-3 p-1'"
      >
        <b-col
          :cols="$store.getters['layout/getInnerWidth'] > 800 ? '' : '12'"
        >
          <div
            v-if="perPageEnabled"
            class="d-flex align-items-center per-page"
            :class="$store.getters['layout/getInnerWidth'] > 800 ? '' : 'per-page-mobile justify-content-center'"
          >
            <span
              class="per-page-label"
              :class="$store.getters['layout/getInnerWidth'] > 800 ? '' : 'pl-3 pr-5'"
            >
              {{ $t('general.table.per-page') }}
            </span>
            <b-form-select
              v-model="perPageCurrent"
              :options="pageOptions"
              @change="onPerPageUpdate"
            />
          </div>
        </b-col>
        <b-col
          :cols="$store.getters['layout/getInnerWidth'] > 800 ? '' : '12'"
          :class="$store.getters['layout/getInnerWidth'] > 800 ? '' : 'mt-4 mb-4'"
        >
          <b-pagination
            v-model="page"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            limit="6"
            class="my-0"
            align="center"
            pills
            small
            size="sm"
          ></b-pagination>
        </b-col>
        <b-col
          class="current-progress d-flex"
          :class="$store.getters['layout/getInnerWidth'] > 800 ? 'justify-content-end' : 'justify-content-center'"
          :cols="$store.getters['layout/getInnerWidth'] > 800 ? '' : '12'"
        >
          {{ currentShow }}
        </b-col>
      </b-row>
    </div>
    <d-modal
      v-if="tmpScope"
      :modalId="`${identifier}-${tmpScope.id}`"
      :display="display"
      :title="modalTitle"
      :text="modalDescription"
      :hide="hide"
      @on:cancel="onCancel"
      @on:confirm="onConfirm"
    />
  </div>
</template>

<script>

import * as tableModule from "@/utils/table";

export default {
  components: tableModule.TABLE_COMPONENTS,
  props: {
    id: {
      type: String,
      default: tableModule.DEFAULT_DATATABLE_ID,
    },
    perPageEnabled: {
      type: Boolean,
      default: false,
    },
    stickyHeader: {
      type: String,
      default: tableModule.DEFAULT_STICKY_HEADER,
    },
    overflowEnabled: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    identifier: {
      type: String,
      default: tableModule.DEFAULT_IDENTIFIER_MODAL,
    },
    emptyText: {
      type: String,
      default: tableModule.DEFAULT_EMPTY_TEXT_TABLE,
    },
    trCursorPointer: {
      type: Boolean,
      default: false,
    },
    deleteModalTitle: {
      type: String,
      default: tableModule.DEFAULT_MODAL_TITLE,
    },
    deleteModalDescription: {
      type: String,
      default: tableModule.DEFAULT_MODAL_DESCRIPTION,
    },
    tablefields: {
      type: Array,
      default: () => [],
    },
    isBusy: {
      type: Boolean,
      default: true,
    },
    hasCheckbox: {
      type: Boolean,
      default: true,
    },
    hasPhoto: {
      type: Boolean,
      default: true,
    },
    totalRows: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    customView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalTitle: null,
      modalDescription: null,
      filter: null,
      modalAction: null,
      isDeleting: false,
      tmpScope: null,
      removeScope: null,
      display: false,
      hide: false,
      timer: null,
      pageCurrent: 1,
      perPageCurrent: 10,
    };
  },
  computed: {
    cssVars() {
      if (this.trCursorPointer) {
        return {
          '--cursor': 'pointer',
          '--hover': '#f1f3f5'
        }
      } else {
        return {
          '--cursor': 'unset',
          '--hover': '#FFF'
        }
      }
    },
    currency() {
      return this.$currency;
    },
    page: {
      get() {
        return this.currentPage;
      },
      set(page) {
        this.pageCurrent = page;
        this.$emit('on:pagination-model:update', page);
        this.$emit('current-page:update', page);
      },
    },
    pageOptions() {
      return [
        {text: 10, value: 10},
        {text: 20, value: 20},
        {text: 50, value: 50},
        {text: 100, value: 100},
      ];
    },
    currentShow() {
      let from = (this.pageCurrent * this.perPageCurrent) - this.perPageCurrent + 1;
      let to = this.pageCurrent * this.perPageCurrent;

      if (to > this.totalRows) {
        to = this.totalRows;
      }

      return this.$t('general.table.current', {from, to, total: this.totalRows});
    }
  },
  methods: {
    onRowRemove(scope) {
      this.tmpScope = scope;
      this.display = !this.display;
    },
    onPlaygroundPhotoUploaded(scope, item) {
      this.$emit('on:playground-photo-uploaded', {photo: scope, row: item})
    },
    onRowClick(scope) {
      this.$emit('row-clicked', scope);
    },
    onCellActionClick(scope, action) {
      this.tmpScope = scope;

      switch (action) {
        case tableModule.DELETE_MODAL_ACTION:
        case tableModule.RAR_MODAL_ACTION:
        case tableModule.UNRAR_MODAL_ACTION:
          this.modalAction = tableModule.DATA_MODAL_ARRAY[action].action;
          this.modalTitle = tableModule.DATA_MODAL_ARRAY[action].title;
          this.modalDescription = tableModule.DATA_MODAL_ARRAY [action].description;

          this.$nextTick(() => {
            this.display = !this.display;
          });
          break;
        case tableModule.EDIT_MODAL_ACTION:
        case tableModule.PAY_MODAL_ACTION:
        case tableModule.SHOW_MODAL_ACTION:
        case tableModule.DETAILS_MODAL_ACTION:
          this.$emit(tableModule.DEFAULT_BTN_EVENT + action, scope);
          break;
        default:
          console.error('Unsupported cell action', action);
      }
    },
    onCancel() {
      this.hide = !this.hide;
    },
    onConfirm() {
      this.$emit('on:' + this.identifier + ':' + this.modalAction, this.tmpScope);
      this.hide = !this.hide;
    },

    onCheckboxSelectionSelected(scope) {
      this.$emit('on:checkbox-selected', scope);
    },
    onRadioCheck(scope) {
      this.$emit('on:radio-button-check', scope);
    },
    onRadioRateCheck(scope) {
      this.$emit('on:radio-button-rate-check', scope);
    },
    onPerPageUpdate(perPage) {
      if (this.perPageEnabled) {
        this.$emit('per-page:update', perPage)
      }
    },
    onSortClick(item) {
      this.$emit('on:sort-by:item', item);
    },
  },
  mounted() {
    this.pageCurrent = this.currentPage;
    this.perPageCurrent = this.perPage;
  }
};
</script>
<style lang="scss" scoped>
@import "@lazy/table/_index.scss";
</style>
