export default {
  saveBlockPriceVariations: (context, payload) => {
    context.commit('setBlockPriceVariations', payload)
  },
  removeBlockPriceVariation: (context, payload) => {
    context.commit('removeBlockPriceVariation', payload)
  },
  addBlockPriceVariation: (context, payload) => {
    context.commit('addBlockPriceVariation', payload)
  },
}
