import Vue from 'vue';

const avatarManagerPlugin = {
  toDataURL (url) {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  },
  avatarPath (token) {
    if (!token) {
      return null;
    }

    const uri = process.env.VUE_APP_UPLOAD_AVATAR_PATH;

    return uri.replace('{token}', token);
  },
  install (Vue, options) {
    Vue.prototype.$avatarPath = this.avatarPath;
  },
};

Vue.use(avatarManagerPlugin);

export default avatarManagerPlugin;
