import store from "@/plugins/store";
import {HOUR_MINUTES_FORMAT} from "@/utils/date";

const moment = require('moment-timezone');

export const currentTimezone = () => {
  return store.getters["auth/currentClub"].timezone;
}

export const toUtc = (date) => {
  return moment.utc(date);
}

export const toTimezone = (date) => {
  return toUtc(date).tz(currentTimezone());
}

export const toTimeFormat = (date) => {
  return toTimezone(date).format(HOUR_MINUTES_FORMAT);
}

export const toTimezoneCustomFormat = (date, format) => {
  return toTimezone(date).format(format);
}

export const toUtcCustomFormat = (date, format) => {
  return toUtc(date).format(format);
}

export const diffUtc = (first, second, format) => {
  return toUtc(first).diff(second, format);
}
