import {_post, _delete } from "@api/doinsport/services/httpService";
import {URL_CLIENT} from "../index";

export const CUSTOMER_IDENTITY_PHOTOS_URL = `${URL_CLIENT}/identity-photos`;

export const postClubClientIdentityPhoto = (formData) => _post(CUSTOMER_IDENTITY_PHOTOS_URL,
  formData,
  {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
;

export const deleteClubClientIdentityPhoto = (id) => _delete(CUSTOMER_IDENTITY_PHOTOS_URL + '/' + id);


