import {clubId, _get, _delete, _post, _put} from "@api/doinsport/services/httpService";

const URI = "/clubs/news";

export const NEWS_URL_PHOTOS = `${URI}/photos`;

export const getClubNews = (itemsPerPage, page, filters= '') => _get(URI +
    '?club.id=' +clubId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page
    + filters
);

export const getClubNewsModel = (id) => _get(URI + '/' + id);

export const deleteClubNews = (id) => _delete(URI + '/' + id);

export const deleteClubNewsPhoto = (id) => _delete(URI + '/photos/' + id);

export const postClubNews = (form) => _post(URI, form);

export const putClubNews = (id, data) => _put(URI + '/' + id, data);

export const postClubNewsPhoto = (formData) => _post(NEWS_URL_PHOTOS,
    formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
;
