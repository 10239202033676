import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  set: (state, payload) => {
    state.validations.push(payload);
  },
  check: (state, payload) => {
    for (const violation of payload.violations) {
      for (const validation of state.validations) {
        if (isNotUndefinedAndNotNull(validation) && isNotUndefinedAndNotNull(validation[violation.propertyPath])) {
          validation[violation.propertyPath].message = violation.message;
          validation[violation.propertyPath].valid = false;
        }
      }
    }
  },
  clear: (state, payload) => {
    for (const key of Object.keys(payload)) {
      payload[key].message = true;
      payload[key].valid = true;
    }
  }
}
