<template>
  <div class="image-background">
    <section class="wrap-login">
      <div class="doinsport-logo">
        <!-- logo image -->
      </div>
      <form class="theme-form">
        <div class="form-group">
          <label> {{ $t('auth.login.username') }}</label>
          <input
            v-model="username"
            class="form-control login-input-border"
            :placeholder="$t('auth.login.username-placeholder')"
            required=""
            type="email"
          />
        </div>
        <div class="form-group">
          <label> {{ $t('auth.login.password') }}</label>
          <input
            v-model="password"
            autocomplete=""
            class="form-control login-input-border"
            :placeholder="$t('auth.login.password-placeholder')"
            required=""
            type="password"
          />
        </div>
        <div class="checkbox p-0">
          <input id="checkbox1" type="checkbox"/>
          <label for="checkbox1">{{ $t("auth.login.remember-me") }}</label>
          <a href="mailto:contact@doinsport.com" class="reset-password pointer"> {{
              $t('auth.login.reset-password')
            }} </a>
        </div>
        <div class="form-group col-md-12 form-row mt-3 mb-0">
          <button
            @click="signIn"
            class="btn-connection"
            type="button"
          >
            {{ $t("auth.login.connect") }}
          </button>
        </div>
      </form>
    </section>

    <!--    Modals-->
    <b-modal
      :title="$t('auth.login.popup.login-error-title')"
      id="modal-login-error"
      centered
      no-close-on-backdrop
      hide-footer
      class="theme-modal"
    >
      <p>{{ $t('auth.login.popup.login-error-description') }}</p>
      <div class="text-center">
        <button
          class="btn btn-primary btn-sm txt-white"
          type="button"
          data-dismiss="modal"
          aria-label="Fermer"
          v-on:click="hidepopup"
        >{{ $t('auth.login.popup.close-btn') }}
        </button>
      </div>
    </b-modal>
    <b-modal size="sm" id="modal-loader-login" centered hide-footer hide-header hide-header-close class="theme-modal">
      <div class="loader-box">
        <h6 class="mr-1">{{ $t('auth.login.popup.login-connecting-description') }}</h6>
        <div class="loader-19"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  created() {
    this.username = "";
    this.password = "";
  },
  mounted() {
    window.addEventListener('keypress', this.singInEnterOrigin, false)
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.singInEnterOrigin);
  },
  methods: {
    singInEnterOrigin(e) {
      if (e.key === 'Enter') {
        this.signIn();
      }
    },
    hidepopup() {
      this.$bvModal.hide('modal-login-error');
    },
    signIn() {
      const username = this.username;
      const password = this.password;
      this.$bvModal.show('modal-loader-login');
      this.$store.dispatch('auth/login', {username, password})
        .then((resp) => {
          this.$store.dispatch('auth/getMe')
            .then(() => {
              this.$store.dispatch('auth/getSelectedClub')
                .then((response) => {
                  const cacheClub = JSON.parse(localStorage.getItem('default-club'));

                  if (isNotUndefinedAndNotNull(cacheClub)) {
                    const userClub = this.$store.getters["auth/currentUser"];

                    if (cacheClub.user.id === userClub.id) {
                      if (cacheClub.club.id !== this.$store.getters["auth/currentClub"].id) {
                        this.$store.dispatch('auth/switchClub', cacheClub.club);
                      }
                    }
                  } else {
                    localStorage.setItem('default-club', JSON.stringify({
                      club: this.$store.getters["auth/currentClub"],
                      user: this.$store.getters["auth/currentUser"]
                    }));
                  }

                  this.$router.push('/');

                  if (cacheClub === null) {
                    setTimeout(() => {
                      this.$router.go(0);
                    }, 1500);
                  }
                })
              ;
              this.$bvModal.hide('modal-loader-login');
              this.password = "";
              this.username = "";
            })
          ;
        })
        .catch(err => {
          this.$bvModal.hide('modal-loader-login');
          this.$bvModal.show('modal-login-error');
        })
      ;
    },
  },
};
</script>
<style scoped>
.image-background {
  background-image: url("../assets/images/doinsport/login-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.wrap-login {
  width: 656px;
  height: 705px;
  border-radius: 9px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.66);
  padding: 91px 145px 110px 146px;
}

.doinsport-logo {
  background-image: url("../assets/images/doinsport/form-login-logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  max-width: 100%;
  height: 129px;
  margin-bottom: 97.26px;
}

input.login-input-border {
  border: #0b1620 1px solid !important;
}

.reset-password {
  float: right;
  margin: 10px 0;
  text-decoration: none;
  color: #242934;
}

.btn-connection {
  border-radius: 24px;
  width: 219px;
  height: 49px;
  margin: 20px auto auto;
  background: linear-gradient(to right, #0a0f50, #f21341);
  box-shadow: 0 3px 6px 0 rgba(255, 129, 132, 0.35);
  color: rgba(255, 255, 255, 1);
  font-size: 19px;
  font-family: Avenir;
  outline: none;
  border: none;
}

.btn-connection:hover {
  background: linear-gradient(to left, #0a0f50, #f21341);
  font-size: 19.2px;
}

@media screen and (max-width: 600px) {
  .wrap-login {
    padding: 5%;
    height: 50%;
  }

  .doinsport-logo {
    margin: 20px auto;
  }
}

</style>
