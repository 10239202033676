import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from "@/locales/en.json";
import nl from "@/locales/nl.json";
import de from "@/locales/de.json";
import it from "@/locales/it.json";
import {defaultLocale, localeOptions} from "@/constants/config";

Vue.use(VueI18n);

const messages = {
  fr: {
    ...require('@/locales/fr.json'),
    validations: {
      ...require('vee-validate/dist/locale/fr').messages,
      ...require('@/locales/validation/fr.json'),
    },
  },
  en: en,
  nl: nl,
  de: de,
  it: it
};

const locale = (localStorage.getItem('language') && localeOptions.filter(x => x.id === localStorage.getItem('language')).length > 0) ? localStorage.getItem('language') : defaultLocale;

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'fr',
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  messages
})

export default i18n;
