import { render, staticRenderFns } from "./BodyTopBar.vue?vue&type=template&id=2255c300&scoped=true&"
import script from "./BodyTopBar.vue?vue&type=script&lang=js&"
export * from "./BodyTopBar.vue?vue&type=script&lang=js&"
import style0 from "./BodyTopBar.vue?vue&type=style&index=0&id=2255c300&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2255c300",
  null
  
)

export default component.exports