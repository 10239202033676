import {_post} from "@api/nextore/services/httpNextoreService";

const URL = 'sales';

export const addSale = (sale) => {
  const params = new URLSearchParams()
  params.append('data', JSON.stringify(sale))

  return _post(URL, params)
}
