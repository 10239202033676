export default {
  savePlaygrounds: (context, payload) => {
    context.commit('setPlaygrounds', payload)
  },
  setLoader: (context, payload) => {
    context.commit('setLoader', payload)
  },
  saveBlockPricesSelection: (context, payload) => {
    context.commit('setSelectedBlockPrices', payload)
  },
  saveTimetablesSelection: (context, payload) => {
    context.commit('setSelectedTimetables', payload)
  },
  saveBlockPricesTotalItems: (context, payload) => {
    context.commit('setBlockPricesTotalItems', payload)
  },
  addBlockPrice: (context, payload) => {
    context.commit('addBlockPrice', payload)
  },
  removeBlockPrice: (context, payload) => {
    context.commit('removeBlockPrice', payload)
  },
  updateBlockPrice: (context, payload) => {
    context.commit('updateBlockPrice', payload)
  },
  addTimetable: (context, payload) => {
    context.commit('addTimetable', payload)
  },
  removeTimetable: (context, payload) => {
    context.commit('removeTimetable', payload)
  },
  updateTimetable: (context, payload) => {
    context.commit('updateTimetable', payload)
  },
  updateBlockPriceSingleSelection: (context, payload) => {
    context.commit('updateBlockPriceSingleSelection', payload)
  },
}
