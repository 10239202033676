export default {
  getStartDate: (state) => {
    return state.startDate;
  },
  getEndDate: (state) => {
    return state.endDate;
  },
  getCurrentPage: (state) => {
    return state.currentPage;
  },
}
