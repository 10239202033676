<template>
  <d-vs-dropdown-form
    :top="hasData(margins) ? margins.top : $attrs.range === '' ? '125px' : ''"
    :left="hasData(margins) ? margins.left : $attrs.range === '' ? '484px' : ''"
  >
    <template v-slot:dropdownbtn>
      <d-button
        :translatedText="fromDateLabel($attrs) === 'general.actions.select' || fromDateLabel($attrs) === 'DD/MM/YYYY - DD/MM/YYYY'"
        :text="label ? label : fromDateLabel($attrs)"
        class="d-btn-sm-padding pl-3 pr-3 btn d-btn-default calendar-text mr-2"
        icon="fa fa-calendar mr-1"
      />
    </template>
    <template v-slot:dropdownbody>
      <d-calendar
        :model="model"
        v-bind="{ ...$attrs }"
        @on:calendar-change="onSelect($event, $attrs)"
      />
    </template>
  </d-vs-dropdown-form>
</template>
<script>
import {DAY_SLASH_MONTH_SLASH_YEAR} from "@/utils/date";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  data: () => ({
    defaultAttrs: null,
  }),
  props: ['model', 'label', 'margins'],
  methods: {
    hasData(val) {
      return isNotUndefinedAndNotNull(val);
    },
    fromDateLabel(attrs) {
      if (attrs && isNotUndefinedAndNotNull(attrs.range)) {
        if (this.$moment.utc(this.model[0]) > (this.$moment.utc(this.model[1]))) {
          return this.formatDateLabel(this.model[1]) + ' - ' + this.formatDateLabel(this.model[0]);
        } else {
          return this.formatDateLabel(this.model[0]) + ' - ' + this.formatDateLabel(this.model[1]);
        }
      } else {
        if (this.model) {
          return this.formatDateLabel(this.model);
        }
      }
      return isNotUndefinedAndNotNull(attrs.range) ? 'DD/MM/YYYY - DD/MM/YYYY' : 'general.actions.select';
    },
    formatDateLabel(date) {
      return this.$moment(date).format(DAY_SLASH_MONTH_SLASH_YEAR)
    },
    onSelect(date, attrs) {
      this.defaultAttrs = attrs;
      this.model = date;
      this.$emit('on:select', date);
    },
  }
}
</script>
