import { extend } from 'vee-validate';

const requireModule = require.context('.', true, /\.js$/);

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return;

  // Replace ./ and .js
  const name = fileName.replace(/(\.\/|\.js)/g, '');
  const componentConfig = requireModule(fileName);

  extend(name, componentConfig.default);
});
