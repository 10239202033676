<template>
     <span
       v-if="iconPosition === 'left'"
       :class="className"
       @click.event.stop="$emit('on:button-click', $event)"
     >
       <i :style="sizeIcon" v-if="icon !== ''" :class="icon"></i>
        {{ translatedText ? $t(text) : text }}
     </span>
  <span
    v-else
    :class="className"
    @click.prevent.stop="$emit('on:button-click', $event)"
  >
        {{ $t(text) }}
    <i :style="sizeIcon" v-if="icon !== ''" :class="icon"></i>
     </span>
</template>
<script>
export default {
  props: {
    iconPosition: {
      type: String,
      default: 'left'
    },
    translatedText: {
      type: Boolean,
      default: true
    },
    iconSize: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    }
  },
  computed: {
    sizeIcon() {
      return 'font-size: ' + this.iconSize + 'px';
    }
  }
}
</script>
<style scoped>
.font-text-title-medium {
  font: normal normal bold 15px robot;
}

.icon-font-size-medium {
  font-size: 14px;
}
</style>
