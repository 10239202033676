import Vue from "vue";
import {hasRole} from "@/utils/role";

const rolePlugin = {
  getCurrentRole(payload) {
    return hasRole(payload)
  },
  install (Vue, options) {
    Vue.prototype.$hasRole = this.getCurrentRole;
  },
}

Vue.use(rolePlugin);

export default rolePlugin;
