<template>
  <d-dropdown-form
    :key="'d-drop-down' + dropDownKey"
    :hide="hide"
    :show="show"
    :right="true"
    min-width="9em"
    padding="0"
    padding-item="0.66rem 1.6rem"
    item-hover="#EDEFF2"
    backgroundColor="#FFFFFF"
    @on:display:change="onChange"
  >
    <template v-slot:dropdownbutton>
      <d-button
        icon="fa fa-cog pointer mt-1 icons-size-cog bg-doinsport-primary ml-2 mr-2"
        @on:button-click="show=!show"
      />
    </template>
    <template v-slot:dropdownbody>
      <div style="cursor: unset" class="padding-params">
        <h6
          class="settings-title ml-4 mt-2 mb-2"
        >
          {{ $t('planning_params.title') }}
        </h6>
      </div>
      <div class="border-bottom"></div>
      <div
        @click="goToSettings()"
        class="dropdown-item simple-label-avenir pointer"
      >

        {{ $t('views.top-bar.settings.general') }}
      </div>
      <div
        class="border-bottom"
      ></div>
      <div
        class="dropdown-item simple-label-avenir pointer"
        @click="goToAccessControl()"
      >
        {{ $t('views.top-bar.settings.access-control') }}
      </div>
      <div class="border-bottom"></div>
      <div
        class="dropdown-item simple-label-avenir pointer"
        @click="goToUsers()"
      >
        {{ $t('views.top-bar.users.title') }}
      </div>
    </template>
  </d-dropdown-form>

</template>
<script>

export default {
  data: () => ({
    show: false,
    hide: false,
    dropDownKey: 0,
  }),
  props: {
    duration: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    onChange (display) {
      this.$bus.$emit('on:drop-menu:display', display);
    },
    goToSettings() {
      this.dropDownKey++;
      if (this.$route.name !== 'settings') {
        this.$router.replace({name: 'settings'})
      }
    },
    goToAccessControl() {
      this.dropDownKey++;
      if (this.$route.name !== 'access-control') {
        this.$router.replace({name: 'access-control'})
      }
    },
    goToUsers() {
      this.dropDownKey++;
      if (this.$route.name !== 'users') {
        this.$router.replace({name: 'users'})
      }
    }
  }
}
</script>
<style scoped>
/deep/ .dropdown-menu {
  min-width: 5rem;
  top: 30px;
}

/deep/ .dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #919194;
}

/deep/ .icons-size-cog {
  font-size: 20px;
}

</style>
