import axios from 'axios';

const VUE_APP_NEXTORE_URL= 'https://caisse.doinsport.club/api';

const currentClub = JSON.parse(localStorage.getItem('current-club'));

const httpNextore = axios.create({
  baseURL: VUE_APP_NEXTORE_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-API-KEY": currentClub ?
      (
        currentClub.nextoreAccount ?
        currentClub.nextoreAccount.apiToken :
        ""
      ) :
      ""
  }
});

export default httpNextore;
