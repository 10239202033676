export const RAR_MODAL_ACTION = 'rar';
export const EDIT_MODAL_ACTION = 'edit';
export const PAY_MODAL_ACTION = 'pay';
export const SHOW_MODAL_ACTION = 'show';
export const DETAILS_MODAL_ACTION = 'details';
export const UNRAR_MODAL_ACTION = 'unrar';
export const DELETE_MODAL_ACTION = 'delete';
export const DEFAULT_STICKY_HEADER = '100%';
export const DEFAULT_DATATABLE_ID = 'dataTableId';
export const DEFAULT_BTN_EVENT = 'on:data-table-btn:';
export const DEFAULT_IDENTIFIER_MODAL = 'data-table-btn';
export const RAR_MODAL_TITLE = 'general.actions.archive-modal-title';
export const DEFAULT_MODAL_TITLE = 'general.actions.delete-modal-title';
export const UNRAR_MODAL_TITLE = 'general.actions.unarchive-modal-title';
export const RAR_MODAL_DESCRIPTION = 'general.actions.archive-modal-text';
export const DEFAULT_MODAL_DESCRIPTION = 'general.actions.delete-modal-text';
export const UNRAR_MODAL_DESCRIPTION = 'general.actions.unarchive-modal-text';
export const DEFAULT_EMPTY_TEXT_TABLE = 'components.doinsport.table.empty-text';

export let DATA_MODAL_ARRAY = [];

DATA_MODAL_ARRAY[RAR_MODAL_ACTION] = { action: RAR_MODAL_ACTION, title: RAR_MODAL_TITLE, description: RAR_MODAL_DESCRIPTION };
DATA_MODAL_ARRAY[UNRAR_MODAL_ACTION] = { action: UNRAR_MODAL_ACTION,  title: UNRAR_MODAL_TITLE, description: UNRAR_MODAL_DESCRIPTION };
DATA_MODAL_ARRAY[DELETE_MODAL_ACTION] = { action: DELETE_MODAL_ACTION,  title: DEFAULT_MODAL_TITLE, description: DEFAULT_MODAL_DESCRIPTION };


export const TABLE_COMPONENTS = {
  BusyTable: () => import('@table/BusyTable'),
  HeadCheckBox: () => import('@table/head/HeadCheckBox'),
  HeadIconLabel: () => import('@table/head/IconLabel'),
  HeadCollapseLabel: () => import('@table/head/CollapseLabel'),
  HeadPhotoLabel: () => import('@table/head/HeadPhotoLabel'),
  CellPreviewCheckBox: () => import('@table/cell/PreviewCheckBox'),
  CellLabelDate: () => import('@table/cell/LabelDate'),
  CellPreview: () => import('@table/cell/Preview'),
  CellCheckbox: () => import('@table/cell/Checkbox'),
  CellCollapseLabel: () => import('@table/cell/CollapseLabel'),
  CellLink: () => import('@table/cell/Link'),
  CellStar: () => import('@table/cell/Star'),
  CellCustom: () => import('@table/cell/Custom'),
  CellNewsStatus: () => import('@table/cell/news/Status'),
  CellCompaignStatus: () => import('@table/cell/compaign/CompaignStatus'),
  CellCampaignValue: () => import('@table/cell/compaign/CampaignSendingValue'),
  CellPlanningParticipantAvatar: () => import('@table/cell/planning/booking-details-modal/ParticipantAvatar'),
  CellPlanningParticipantConfirmed: () => import('@table/cell/planning/booking-details-modal/Confirmed'),
  CellPlanningActions: () => import('@table/cell/planning/booking-details-modal/Actions'),
  CellCreditsPackItems: () => import('@table/cell/credits-pack/CreditsPackItems'),
  CellPlanningDelete: () => import('@table/cell/planning/booking-details-modal/Delete'),
  CellPlanningParticipantPaymentStatus: () => import('@table/cell/planning/booking-details-modal/ParticipantPaymentStatus'),
  CellFromDate: () => import('@table/cell/FromDate'),
  CellToDate: () => import('@table/cell/ToDate'),
  CellSingleSelection: () => import('@table/cell/SingleSelection'),
  CellBookingParticipants: () => import('@table/cell/bookings/multiple-booking/BookingParticipants'),
  CellBookingTimetableBlockPrice: () => import('@table/cell/bookings/multiple-booking/BookingTimetableBlockPrice'),
  CellParticipantPresence: () => import('@table/cell/bookings/multiple-booking/ParticipantPresence'),
  CellBookingPayment: () => import('@table/cell/bookings/multiple-booking/BookingPayment'),
  CellBookingActions: () => import('@table/cell/bookings/multiple-booking/BookingActions'),
  CellSingleSelectionRate: () => import('@table/cell/SingleSelectionRate'),
  CellSelectionCheckbox: () => import('@table/cell/SelectionCheckbox'),
  CellActions: () => import('@table/cell/actions/Index'),
  CellRulesSubscriptionPlans: () => import('@table/cell/access-control/access-rules/RulesSubscriptionPlans'),
  CellRulesAreaIds: () => import('@table/cell/access-control/access-rules/RulesAreaIds'),
  CellRulesCategory: () => import('@table/cell/access-control/access-rules/RulesCategory'),
  CellPlaygroundAvailability: () => import('@table/cell/playground/AvailabilityBadge'),
  CellPlaygroundActivity: () => import('@table/cell/playground/Activity'),
  CellPlaygroundTimetables: () => import('@table/cell/playground/Timetables'),
  CellPlaygroundsBadge: () => import('@table/cell/playground/PlaygroundsBadge'),
  CellDaysOfTheWeek: () => import('@table/cell/playground/new/step-2/DaysOfTheWeek'),
  CellParametersFormsFields: () => import('@table/cell/playground/parameters/forms/Fields'),
  CellPlaygroundDuration: () => import('@table/cell/playground/new/step-2/Duration'),
  CellTimeZoneBlock: () => import('@table/cell/playground/new/step-2/Block'),
  CellPaymentMethods: () => import('@table/cell/playground/new/step-2/PaymentMethods'),
  CellPlaygroundBadgeStatus: () => import('@table/cell/playground/new/step-2/BadgeStatus'),
  CellPlaygroundCustomEditButton: () => import('@table/cell/playground/CustomEditButton'),
  CellPlusIcon: () => import('@table/cell/PlusIcon'),
  CellMinusIcon: () => import('@table/cell/MinusIcon'),
  CellBookingsStatus: () => import('@table/cell/bookings/StatusBadge'),
  CellBookingsDate: () => import('@table/cell/bookings/Date'),
  CellBookingsDuration: () => import('@table/cell/bookings/Duration'),
  CellParticipantPreview: () => import('@table/cell/bookings/participants/ParticipantPreview'),
  CellParticipantAbout: () => import('@table/cell/bookings/participants/ParticipantAbout'),
  CellColPaid: () => import('@table/cell/bookings/participants/ColPaid'),
  CellColToPay: () => import('@table/cell/bookings/participants/ColToPay'),
  CellBookingsRecurring: () => import('@table/cell/bookings/RecurringReservation'),
  CellParticipantToPay: () => import('@table/cell/bookings/ToPay'),
  CellParticipantOwner: () => import('@table/cell/bookings/participants/BookingOwner'),
  CellPaymentStatus: () => import('@table/cell/payment/StatusBadge'),
  CellPayoutStatus: () => import('@table/cell/payment/PayoutStatus'),
  CellPaymentBill: () => import('@table/cell/payment/BillButton'),
  CellPayoutBill: () => import('@table/cell/payment/PayoutBill'),
  CellEventsParticipants: () => import('@table/cell/events/ParticipantsBadge'),
  CellEventsBook: () => import('@table/cell/events/BookButton'),
  CellClientSubscription: () => import('@table/cell/client/SubscriptionBadge'),
  CellClientReservedBy: () => import('@table/cell/client/ReservedBy'),
  CellSubscriptionStartAt: () => import('@table/cell/client/EarliestSubscriptionPlanStartAt'),
  CellSubscriptionEndAt: () => import('@table/cell/client/LatestSubscriptionPlanEndAt'),
  CellClientHistoryPaymentTokenCreditValue: () => import('@table/cell/client/history/CreditHistoryValue'),
  CellClientHistoryWalletValue: () => import('@table/cell/client/history/WalletHistoryValue'),
  CellPrice: () => import('@table/cell/shop/Price'),
  CellRegulation: () => import('@table/cell/shop/Regulation'),
  CellSalesStatus: () => import('@table/cell/sales/SalesStatus'),
  CellBookingsCategory: () => import('@table/cell/bookings/BookingCategory')
}

export default {
  TABLE_COMPONENTS,
  DEFAULT_BTN_EVENT,
  DETAILS_MODAL_ACTION,
  SHOW_MODAL_ACTION,
  PAY_MODAL_ACTION,
  EDIT_MODAL_ACTION,
  DATA_MODAL_ARRAY,
  DEFAULT_MODAL_DESCRIPTION,
  DEFAULT_MODAL_TITLE,
  UNRAR_MODAL_DESCRIPTION,
  DELETE_MODAL_ACTION,
  UNRAR_MODAL_TITLE,
  UNRAR_MODAL_ACTION,
  DEFAULT_EMPTY_TEXT_TABLE,
  DEFAULT_IDENTIFIER_MODAL,
  DEFAULT_STICKY_HEADER,
  RAR_MODAL_DESCRIPTION,
  RAR_MODAL_TITLE,
  RAR_MODAL_ACTION
};
