import {_delete, _post, _put} from "@api/doinsport/services/httpService";

export const CLUB_URL_LOGO = 'clubs/logos';

const headers = { "Content-Type": "multipart/form-data" };

export const postClubLogo = (formData) => _post(CLUB_URL_LOGO,
    formData, {
        headers: headers
    })
;

export const putClubLogo = (id, formData) => _put(CLUB_URL_LOGO + '/' + id,
    formData, {
        headers: headers
    })
;

export const deleteClubLogo = (iri) => _delete(iri);
