import {_post, _delete} from "@api/doinsport/services/httpService";
import { USER_CLUB_URL } from "../user-club.api";

export const AVATARS_URL = `${USER_CLUB_URL}/avatars`;

export const postUserClubAvatar = (formData) => _post(AVATARS_URL,
    formData,
    {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
;

export const deleteUserClubAvatar = (id) => _delete(AVATARS_URL + '/' + id);


