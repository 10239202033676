import Vue from 'vue';

const eventBusPlugin = {
  install (Vue, options) {
    Vue.prototype.$bus = new Vue();
  },
};

Vue.use(eventBusPlugin);

export default eventBusPlugin;
