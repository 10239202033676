<template>
  <div>
    <avatar
      :style="cssVars"
      :fullname="fullName"
      :size="size"
      :radius="radius"
      :image="image"
      :color="color"
      @click="action"
    />
  </div>
</template>

<script>
import Avatar from 'vue-avatar-component'

export default {
  components: {Avatar},
  props: [
    'fullName',
    'size',
    'radius',
    'image',
    'color',
    'textColor',
    'textFontSize'
  ],
  methods: {
    action() {
      this.$emit('on:click');
    }
  },
  computed: {
    cssVars() {
      return {
        '--text-color': this.textColor ? this.textColor : '#FFF',
        '--text-font-size': this.textFontSize ? this.textFontSize + 'px' : 'unset',
      }
    }
  }
}
</script>
<style scoped>
/deep/ .avatar table td {
  text-align: center;
  vertical-align: middle;
  color: var(--text-color);
  font-size: var(--text-font-size);
}
</style>
