<template>
  <b-modal
    :id="'modal-delete-'+ modalId"
    hide-header
    hide-footer
    hide-header-close
    size="lg"
    header-class="my-second-class"
    :body-bg-variant="'gray-lighten'"
  >
    <b-row>
      <b-col align="center" class="modal-title-class">
        {{ $t(title) }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3">
    </div>
    <b-row>
      <b-col align="center" class="">
        {{ $t(text) }}
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col
        :align="innerWidth ? 'center' : 'right'"
        :class="innerWidth ? 'pb-2 pl-3 pr-3 ' : 'pr-0'"
        :cols="innerWidth ? '6' : ''"
      >
        <d-button
          :text="'general.actions.cancel'"
          :class="innerWidth ? 'custom-customer-button' : ''"
          class="d-btn-sm d-btn btn d-btn-default font-text-title"
          @on:button-click="$emit('on:cancel')"
        />
      </b-col>
      <b-col
        :align="innerWidth ? 'center' : 'left'"
        :cols="innerWidth ? '6' : ''"
        :class="innerWidth ? 'pb-2 pl-3 pr-3' : 'pr-0'"
      >
        <d-button
          v-if="!confirmed"
          :text="'general.actions.confirm'"
          class="d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title"
          :class="innerWidth ? 'custom-customer-button' : ''"
          @on:button-click="confirmed = true; $emit('on:confirm')"
        />
        <d-button
          v-else
          :class="[confirmed ? 'disabled' : '', innerWidth ? 'custom-customer-button' : '']"
          :icon="confirmed ? 'fa fa-refresh fa-spin' : ''"
          :icon-position="'right'"
          text="general.actions.confirm"
          class="d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>

export default {
  data: () => ({
    confirmed: false,
  }),
  props: {
    text: {
      type: String,
      default: 'general.actions.delete-modal-text'
    },
    modalId: {
      type: String,
      default: 'default'
    },
    title: {
      type: String,
      default: 'general.actions.delete-modal-title'
    },
    display: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    display: function (changes) {
      this.confirmed = false;
      this.$bvModal.show('modal-delete-' + this.modalId);
    },
    hide: function (changes) {
      this.$bvModal.hide('modal-delete-' + this.modalId);
    }
  },
  computed:{
    innerWidth(){
      return this.$store.getters['layout/getInnerWidth'] <= 800;
    }
  },
  beforeDestroy() {
    try {
    } catch (e) {
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/_custom-buttom-mobile.scss";

.modal-title-class {
  text-align: center;
  font: normal normal 500 30px Avenir;
  letter-spacing: 0px;
  color: #3C3D43;
  opacity: 1;
}

/deep/ .modal {
  top: 100px
}

/deep/ .modal-content {
  border-radius: 8px;
  width: calc(100% - 150px);
  left: 100px;
}

@media only screen and (max-width: 991px) {
  /deep/ .modal-content {
    width: 100%;
    left: 0;
  }
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}
</style>
