<template>
  <div :class="containerClass">
    <b-row
        no-gutters
    >
      <b-col>
        <h2 class="f-w-700"> {{ $t(title) }}</h2>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      containerClass: {
        type: String,
        default: ''
      }
    }
  }
</script>