<template>
  <div>
    <datetime
      v-model="model"
      v-bind="{ ...$attrs }"
      :class="identifier"
      :min-datetime="formattedMinDateTime"
      class="theme-grey"
      hidden
      type="datetime"
      format="yyyy-MM-dd HH:mm"
    >
      <template slot="button-cancel">
        {{ $t('general.actions.cancel') }}
      </template>
      <template slot="button-confirm">
        <div @click="goToNextStep">
          {{ step === 0 ?  $t('general.actions.next') :  $t('general.actions.save')}}
        </div>
      </template>
    </datetime>
  </div>
</template>
<script>
import { Datetime } from 'vue-datetime';
import { Settings } from 'luxon';
import 'vue-datetime/dist/vue-datetime.css';

export default {
  data: () => ({
    step: 0,
  }),
  props: {
    identifier: {
      type: String,
      default: 'datePickerIdentifier'
    },
    minDateTime: {
      type: String,
      default: null
    },
    dateTime: {
      type: String,
      default: null
    },
    model: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Datetime,
  },
  watch: {
    model: function () {
      if (this.$moment(this.model, this.$moment.ISO_8601, true).isValid()) {
        if (this.step > 0) {
          this.$emit('date-time:update', this.model);
        }
      }
    },
    show: function () {
      this.step = 0;
      this.model = this.$moment(this.dateTime).format('YYYY-MM-DDTHH:mm:ss');
      Settings.defaultLocale = this.$i18n.locale;
      document.querySelector('.' + this.identifier + ' .vdatetime-input').click();
    }
  },
  computed: {
    formattedMinDateTime () {
      return  this.minDateTime ? this.$moment(this.minDateTime).format('YYYY-MM-DDTHH:mm:ss') : null;
    }
  },
  methods: {
    goToNextStep() {
        this.step ++;
    },
  },
}
</script>
<style scoped lang="scss">
@import "@lazy/_date-time-picker.scss";
</style>
