import './moment';
import './vuesax';
import('./chartist');
import './ck-editor';
import './qr-component';
import './components';
import './timepicker';
import './vee-validate';
import './bootstrap-vue';
import './vue-tel-input';
import './extra-packages';
import '@/assets/scss/app.scss';

import store from './store';

import i18n from './i18n';
import VueHtml2Canvas from './html2canvas';
import JQuery from 'jquery';
import router from './router';
import flashPlugin from './flash';
import fileManager from './file-manager';
import eventBusPlugin from './bus-event';
import avatarManager from './avatar-manager';
import currencyManager from './currency-manager';
import timezoneManager from './timezone-manager';
import perfectScrollbar from './perfect-scrollbar';
import rolePlugin from "@plugins/role";

window.$ = JQuery;

export default {
  store,
  i18n,
  VueHtml2Canvas,
  router,
  fileManager,
  rolePlugin,
  flashPlugin,
  avatarManager,
  eventBusPlugin,
  currencyManager,
  timezoneManager,
  perfectScrollbar,
}

