<template>
  <dropdown-menu
    v-model="display"
    v-bind="{...$props, ...$attrs}"
    transition="translate-fade-down"
    :interactive="true"
    :style="cssVars"
  >
    <button :id="identifier" hidden></button>
    <slot name="dropdownbutton">
    </slot>
    <div slot="dropdown">
      <slot name="dropdownbody">
      </slot>
    </div>
  </dropdown-menu>
</template>
<script>
export default {
  data: () => ({
    active: '',
    identifier: '',
    key: 0,
    display: false,
    renderComponent: true,
  }),
  props: {
    hide: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: '#F8FAFA',
    },
    itemHover: {
      type: String,
      default: '#F8FAFA',
    },
    minWidth: {
      type: String,
      default: '13em',
    },
    padding: {
      type: String,
      default: '0.3em',
    },
    paddingItem: {
      type: String,
      default: 'auto',
    },
    show: {
      type: Boolean,
      default: true,
    },
    identifier: {
      type: String,
      default: 'default',
    },
  },
  components: {
    DropdownMenu: () => import ('@innologica/vue-dropdown-menu')
  },
  computed: {
    cssVars() {
      return {
        '--background-color': this.backgroundColor,
        '--min-width': this.minWidth,
        '--padding': this.padding,
        '--padding-item': this.paddingItem,
        '--background-item-color-hover': this.itemHover
      }
    },
  },
  watch: {
    display: function() {
      this.$emit('on:display:change', this.display);
    },
    show: function (val) {
      document.getElementById(this.identifier).click();
    },
    hide: function (val) {
     this.display = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@lazy/_dropdown-menu.scss";
</style>
