export default {
  getStatsParams: (state) => {
    return state.stats;
  },
  getNbCharts: (state) => {
    let nb = 0;

    for (const row of state.stats.rows) {
      nb += row.length;
    }

    return nb;
  }
}
