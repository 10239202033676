<template>
  <div>
    <vs-radio
      :key="radioKey"
      class="pointer"
      @change.stop="onClick"
      :color="color"
      :vs-value="false"
      :disabled="disabled"
      v-model="row.singleSelection"
    >
      {{ text }}
    </vs-radio>
  </div>
</template>
<script>
export default {
  data: () => ({
    radio: 'light',
    radioKey: 0,
  }),
  props: {
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#0B2772'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: this,
    }
  },
  watch: {
    disabled: function () {
      if (this.disabled) {
        this.row.singleSelection = true;
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('on:radio-button-check', this.row);
    }
  }
}
</script>
<style>
.radio-style {
  border: 2px solid #F1F4F7;
}

.vs-radio--circle {
  border: #0c5460;
}
</style>
