<template>
  <div class="img-container ml-5" slot="body">
    <img
      class="pointer loader-booking d-flex align-items-center align-content-center justify-content-center"
      :src="require('@/assets/planning-loader.svg')"
    />
  </div>
</template>
<script>
  export default {

  }
</script>
